import TranslationKeys from "../../../../../../features/translations/TranslationKeys"
import { dateFormatter, numberFormatter, percentageFormatter, priceFormatter } from "../../../../../../components/dataGrid/dataGridValueFormatters"
import RemarksCell from "../components/remarksCell/RemarksCell"
import QuoteRelevantCell from "../components/quoteRelevantCell/QuoteRelevantCell"
import { renderProductNameCell } from "../components/productNameCell/ProductNameCell"
import PlusCodeCell from "../components/plusCodeCell/PlusCodeCell"
import ActionsCell from "../components/actionsCell/ActionsCell"
import getPlusCodeCellValue from "../components/plusCodeCell/getPlusCodeCellValue"
import { DragAndDropButtonCell } from "../components/dragAndDropButtonCell/DragAndDropButtonCell"
import renderEmptyHeader from "../../../../../../components/dataGrid/utils/renderEmptyHeader"
import renderHeaderWithCurrency from "../utils/renderHeaderWithCurrency"
import LineItemTypeCell from "../components/lineItemTypeCell/LineItemTypeCell"
import { LineItemProperties, SalesDocumentProperties } from "../../../../../../features/sales/sales.constants"
import { GridColDef } from "@mui/x-data-grid-pro"
import { L10n } from "@encoway/l10n"

const lineItemsDataGridColumns: GridColDef[] = [
    {
        field: "dragAndDrop",
        headerName: TranslationKeys.components.dataGrid.columns.dragAndDrop,
        width: 50,
        editable: false,
        renderCell: DragAndDropButtonCell,
        renderHeader: renderEmptyHeader,
        sortable: false,
        filterable: false
    },
    {
        field: LineItemProperties.QUOTE_RELEVANT,
        headerName: TranslationKeys.lineItem.properties.quoteRelevant,
        renderCell: QuoteRelevantCell,
        type: "boolean",
        editable: false,
        sortable: false,
        filterable: false
    },
    {
        field: LineItemProperties.POSITION_NUMBER,
        headerName: TranslationKeys.lineItem.properties.positionNumber,
        width: 80
    },
    {
        field: LineItemProperties.POSITION_QUANTITY,
        headerName: TranslationKeys.lineItem.properties.positionQuantity,
        valueFormatter: numberFormatter,
        type: "number",
        width: 75,
        preProcessEditCellProps: params => ({
            ...params.props,
            error:
                typeof params.props.value !== "number" || params.props.value < 0
                    ? L10n.format(TranslationKeys.pages.project.composition.invalidQuantityMessage)
                    : undefined
        })
    },
    {
        field: "remarks",
        headerName: TranslationKeys.pages.project.composition.remarks,
        editable: false,
        sortable: false,
        filterable: false,
        renderCell: RemarksCell
    },
    {
        field: "articleType",
        headerName: TranslationKeys.lineItem.types.label,
        width: 0,
        editable: false,
        sortable: false,
        filterable: false,
        renderCell: LineItemTypeCell,
        renderHeader: renderEmptyHeader
    },
    {
        field: LineItemProperties.PRODUCT_NAME,
        headerName: TranslationKeys.lineItem.properties.productName,
        width: 200,
        flex: 1,
        renderCell: renderProductNameCell
    },
    {
        field: LineItemProperties.SHORT_TEXT,
        headerName: TranslationKeys.lineItem.properties.plusCode,
        width: 200,
        flex: 1,
        renderCell: PlusCodeCell,
        valueGetter: getPlusCodeCellValue
    },
    {
        field: LineItemProperties.ARTICLE_ID,
        headerName: TranslationKeys.lineItem.properties.articleId,
        width: 215
    },
    {
        field: "option",
        headerName: TranslationKeys.lineItem.properties.option,
        width: 80,
        editable: false
    },
    {
        field: LineItemProperties.PLAIN_LIST_PRICE,
        headerName: TranslationKeys.lineItem.properties.plainListPrice,
        valueFormatter: priceFormatter,
        type: "number",
        renderHeader: renderHeaderWithCurrency(SalesDocumentProperties.PRICES_CURRENCY)
    },
    {
        field: LineItemProperties.PLAIN_LIST_PRICE_WITH_CURRENCY,
        headerName: TranslationKeys.lineItem.properties.plainListPriceWithCurrency,
        valueFormatter: priceFormatter,
        type: "number",
        renderHeader: renderHeaderWithCurrency(SalesDocumentProperties.QUOTE_CURRENCY)
    },
    {
        field: LineItemProperties.MULTIPLIER,
        headerName: TranslationKeys.lineItem.properties.multiplier,
        valueFormatter: priceFormatter
    },
    {
        field: LineItemProperties.PRICEGROUP,
        headerName: TranslationKeys.lineItem.properties.priceGroup
    },
    {
        field: LineItemProperties.CALCULATED_TRANSFER_PRICE,
        headerName: TranslationKeys.lineItem.properties.plainTransferPrice,
        valueFormatter: priceFormatter,
        type: "number",
        renderHeader: renderHeaderWithCurrency(SalesDocumentProperties.QUOTE_CURRENCY)
    },
    {
        field: LineItemProperties.TOTAL_LIST_PRICE,
        headerName: TranslationKeys.lineItem.properties.totalListPrice,
        valueFormatter: priceFormatter,
        type: "number",
        renderHeader: renderHeaderWithCurrency(SalesDocumentProperties.QUOTE_CURRENCY)
    },
    {
        field: LineItemProperties.CALCULATED_TOTAL_TRANSFER_PRICE,
        headerName: TranslationKeys.lineItem.properties.totalTransferPrice,
        valueFormatter: priceFormatter,
        type: "number",
        renderHeader: renderHeaderWithCurrency(SalesDocumentProperties.QUOTE_CURRENCY)
    },
    {
        field: LineItemProperties.TRANSFER_PRICE_DISCOUNT_PERCENTAGE,
        headerName: TranslationKeys.lineItem.properties.transferPriceDiscountPercentage,
        valueFormatter: percentageFormatter,
        type: "number",
        valueGetter: params => {
            if (params.row.lineItem.properties[params.field] === -999) {
                // NOTE: Workaround for PSU-5531
                return ""
            } else {
                return params.row.lineItem.properties[params.field]
            }
        },
        preProcessEditCellProps: params => ({
            ...params.props,
            error:
                typeof params.props.value !== "number" && params.props.value
                    ? L10n.format(TranslationKeys.pages.project.composition.invalidNumberMessage)
                    : undefined
        })
    },
    {
        field: LineItemProperties.TRANSFER_PRICE_DISCOUNTED_UNIT_PRICE,
        headerName: TranslationKeys.lineItem.properties.transferPriceDiscountedUnitPrice,
        valueFormatter: priceFormatter,
        type: "number",
        renderHeader: renderHeaderWithCurrency(SalesDocumentProperties.QUOTE_CURRENCY)
    },
    {
        field: LineItemProperties.TRANSFER_PRICE_DISCOUNT_ACCUMULATED_PERCENTAGE,
        headerName: TranslationKeys.lineItem.properties.transferPriceDiscountAccumulatedPercentage,
        valueFormatter: percentageFormatter,
        type: "number"
    },
    {
        field: LineItemProperties.TRANSFER_PRICE_DISCOUNTED_TOTAL_PRICE,
        headerName: TranslationKeys.lineItem.properties.transferPriceDiscountedTotalPrice,
        valueFormatter: priceFormatter,
        type: "number",
        renderHeader: renderHeaderWithCurrency(SalesDocumentProperties.QUOTE_CURRENCY)
    },
    {
        field: LineItemProperties.ABB_LINE_ITEM_ID,
        headerName: TranslationKeys.lineItem.properties.id,
        width: 120
    },
    {
        field: LineItemProperties.CONFIGURATION_NAME,
        headerName: TranslationKeys.lineItem.properties.configurationName,
        width: 200
    },
    {
        field: LineItemProperties.CREATED_BY,
        headerName: TranslationKeys.lineItem.properties.createdBy,
        width: 150
    },
    {
        field: LineItemProperties.CREATED_AT,
        headerName: TranslationKeys.lineItem.properties.createdAt,
        type: "date",
        valueFormatter: dateFormatter
    },
    {
        field: LineItemProperties.MODIFIED_AT,
        headerName: TranslationKeys.lineItem.properties.modifiedAt,
        type: "date",
        width: 150,
        valueFormatter: dateFormatter
    },
    {
        field: LineItemProperties.MODIFIED_BY,
        headerName: TranslationKeys.lineItem.properties.modifiedBy
    },
    {
        field: "actions",
        headerName: TranslationKeys.components.dataGrid.columns.actions,
        type: "actions",
        width: 10,
        hideable: false,
        editable: false,
        renderHeader: renderEmptyHeader,
        getActions: ActionsCell
    }
]

export default lineItemsDataGridColumns
