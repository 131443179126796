import { GridRenderCellParams } from "@mui/x-data-grid-pro"
import { Product } from "@encoway/c-services-js-client"
import CatalogUtils from "../../../../../../../../features/catalog/catalog.utils"
import { Characteristics } from "../../../../../../../../features/catalog/catalog.constants"
import TextField from "@mui/material/TextField"
import SalesApi from "../../../../../../../../features/sales/sales.api"
import { useAppSelector } from "../../../../../../../../store/store"
import { Button, Stack } from "@mui/material"
import { ChangeEvent, useContext, useEffect, useState } from "react"
import { ProductQuickSelectionDataGridContext } from "../../ProductQuickSelectionDataGrid"
import { ProductQuantityCellStyles } from "./ProductQuantityCell.styles"
import AddIcon from "@mui/icons-material/Add"
import RemoveIcon from "@mui/icons-material/Remove"

const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (!["Backspace", "Tab"].includes(event.key) && !/^\d+$/.test(event.key)) {
        event.preventDefault()
    }
}

export function ProductQuantityCell(params: Readonly<GridRenderCellParams<Product>>) {
    const context = useContext(ProductQuickSelectionDataGridContext)
    const contextQuantity = context?.selectedProducts?.[params.row.id]
    const [quantity, setQuantity] = useState(contextQuantity)
    const productCategory = SalesApi.endpoints.salesDocument.useQueryState().data?.properties.PRODUCT_CATEGORY
    const readOnly = useAppSelector(state => state.sales.salesDocumentReadOnly)
    const characteristicValue = CatalogUtils.getCharacteristicValue<string>(params.row, Characteristics.ProductCategory.id)

    useEffect(() => setQuantity(contextQuantity), [contextQuantity])

    const updateQuantity = (newQuantity: number) => {
        if (context && newQuantity < 1000) {
            setQuantity(newQuantity === 0 ? undefined : newQuantity)
            context.updateProductQuantity(context.productGroupId, params.row.id, newQuantity)
        }
    }

    const increment = () => {
        updateQuantity(quantity ? quantity + 1 : 1)
    }

    const decrement = () => {
        if (quantity) {
            updateQuantity(quantity - 1)
        }
    }

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target.value) {
            updateQuantity(0)
        } else if (event.target.value) {
            updateQuantity(parseInt(event.target.value))
        }
    }

    return (
        <Stack direction="row" alignItems="center" spacing="1px" width="100%">
            <TextField
                value={quantity ?? ""}
                onChange={onChange}
                disabled={(!!productCategory && !!characteristicValue && productCategory !== characteristicValue) || readOnly}
                onKeyDown={onKeyDown}
                type="number"
                sx={ProductQuantityCellStyles.textField}
                InputProps={{ inputProps: { min: 0, max: 999 } }}
            />
            <Button variant="contained" color="secondary" sx={ProductQuantityCellStyles.button} tabIndex={-1} disabled={quantity === 999} onClick={increment}>
                <AddIcon fontSize="small" />
            </Button>
            <Button variant="contained" color="secondary" sx={ProductQuantityCellStyles.button} tabIndex={-1} disabled={!quantity} onClick={decrement}>
                <RemoveIcon fontSize="small" />
            </Button>
        </Stack>
    )
}
