import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../../features/translations/TranslationKeys"
import { Typography } from "@mui/material"
import theme from "../../../App.theme"
import Dialog, { DialogProps } from "../../dialog/Dialog"
import { ButtonPropsWithLabel } from "../../dialog/dialogActions/DialogActions"
import DialogStyles from "../../dialog/Dialog.styles"

interface LockedProjectDialogProps extends Pick<DialogProps, "open"> {
    projectCanBeOpened: boolean
    lockedBy?: string
    onOpenLockedProject: (readOnly?: boolean) => void
    onCancelOpen: () => void
}

export default function LockedProjectDialog({ open, projectCanBeOpened, lockedBy, onOpenLockedProject, onCancelOpen }: LockedProjectDialogProps) {
    const stealLockButton: ButtonPropsWithLabel = {
        label: TranslationKeys.project.lockedDialog.submitButtonLabel,
        variant: "contained",
        onClick: () => onOpenLockedProject(),
        sx: DialogStyles.blackButton
    }

    const readButton: ButtonPropsWithLabel = {
        label: TranslationKeys.project.actions.read,
        variant: "contained",
        onClick: () => onOpenLockedProject(true),
        sx: DialogStyles.grayButton
    }

    return (
        <Dialog
            open={open}
            onClose={onCancelOpen}
            title={L10n.format(TranslationKeys.project.lockedDialog.title)}
            message={L10n.format(TranslationKeys.project.lockedDialog.message, { user: lockedBy })}
            customDialogActions={projectCanBeOpened ? { actions: [readButton, stealLockButton] } : undefined}
        >
            {projectCanBeOpened ? (
                <Typography color={theme.palette.warning.main}>{L10n.format(TranslationKeys.project.lockedDialog.unlockWarning)}</Typography>
            ) : null}
        </Dialog>
    )
}
