import { GridCellParams, GridColDef, GridRowClassNameParams } from "@mui/x-data-grid-pro"
import SalesUtils from "../../../../../features/sales/sales.utils"
import { FIRST_LEVEL_ITEM_CLASS_NAME } from "./LineItemsDataGrid.styles"
import { BomNode } from "./LineItemsDataGrid"
import { AbbSalesDocumentEntity } from "../../../../../features/sales/sales.types"
import { LineItemProperties } from "../../../../../features/sales/sales.constants"
import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../../../../features/translations/TranslationKeys"
import { Composition } from "../../../../../features/sales/sales.api.types"
import getCellValue from "./utils/getCellValue"
import setCellValue from "./utils/setCellValue"
import { UserInformation } from "../../../../../features/oidc/hooks/useUserInformation"
import { OidcUtils } from "../../../../../features/oidc/oidc.utils"
import { transferPricesProperties } from "./constants/transferPricesProperties"
import { listPricesProperties } from "./constants/listPricesProperties"

export const isCellEditable = (params: GridCellParams<BomNode>) => {
    return (
        SalesUtils.lineItems.isEditableLineItemProperty(params.row.lineItem, params.field) &&
        params.row.lineItem.properties.COMMON_OPTION_SET_BY_SYSTEM !== "true"
    )
}

export const getRowClassName = (params: GridRowClassNameParams<BomNode>) => {
    return params.row.level === 0 ? FIRST_LEVEL_ITEM_CLASS_NAME : ""
}

export const getFooterRow = (salesDocument: AbbSalesDocumentEntity, userInformation?: UserInformation): BomNode => {
    return {
        expanded: true,
        level: 1, // empty quote relevant cell
        lineItem: {
            isSalesDocument: true,
            lineItemId: salesDocument.salesDocumentId,
            permission: salesDocument.permission,
            propertyPermissions: {
                transferPriceDiscountPercentage: OidcUtils.hasUpdateTransferPricesDiscountRole(userInformation) ? ["READ", "UPDATE"] : ["READ"]
            },
            configurationPermission: [],
            properties: {
                isSubarticle: true, // disable actions
                isLeaf: true, // disable toggle at product name cell
                positionnumber: L10n.format(TranslationKeys.pages.project.composition.total),
                totalListPrice: salesDocument.calculationProperties.totalListPrice,
                calculatedTransferPrice: salesDocument.calculationProperties.calculatedTotalTransferPrice,
                calculatedTotalTransferPrice: salesDocument.calculationProperties.calculatedTotalTransferPrice,
                totalTransferPrice: salesDocument.calculationProperties.totalTransferPrice,
                transferPriceDiscountPercentage: salesDocument.calculationProperties.transferPriceDiscountPercentage,
                transferPriceDiscountedUnitPrice: salesDocument.calculationProperties.transferPriceDiscountedUnitPrice,
                transferPriceDiscountedTotalPrice: salesDocument.calculationProperties.transferPriceDiscountedTotalPrice,
                transferPriceDiscountAccumulatedPercentage: salesDocument.calculationProperties.transferPriceDiscountAccumulatedPercentage
            }
        }
    }
}

export const getRows = (composition?: Composition, parent?: BomNode): BomNode[] => {
    const lineItems = composition?.[parent?.lineItem.lineItemId ?? "ROOT"] ?? []
    return lineItems.flatMap(lineItem => {
        const expanded = Object.keys(composition!).includes(lineItem.lineItemId)
        const bomNode: BomNode = { lineItem: lineItem, parent, expanded, level: parent ? parent.level + 1 : 0 }
        if (expanded) bomNode.children = getRows(composition, bomNode)
        return [bomNode].concat(bomNode.children ?? [])
    })
}

export const mapColumn = (column: GridColDef, readOnly: boolean): GridColDef => ({
    ...column,
    headerName: column.headerName ? L10n.format(column.headerName) : column.headerName,
    valueGetter: column.valueGetter ?? getCellValue,
    valueSetter: column.valueSetter ?? (params => setCellValue(params, column.field)),
    editable: column.editable !== false && !readOnly,
    disableColumnMenu: column.disableColumnMenu || (column.sortable === false && column.filterable === false)
})

export const filterColumn = (column: GridColDef, userInformation?: UserInformation): boolean => {
    const isTransferPriceProperty = transferPricesProperties.includes(column.field)
    const isListPriceProperty = listPricesProperties.includes(column.field)
    if (isTransferPriceProperty) {
        return OidcUtils.hasReadTransferPricesRole(userInformation)
    } else if (isListPriceProperty) {
        return OidcUtils.hasReadListPricesRole(userInformation)
    } else if (column.field === LineItemProperties.MULTIPLIER) {
        return OidcUtils.hasReadMultiplierRole(userInformation)
    } else if (column.field === LineItemProperties.TRANSFER_PRICE_DISCOUNT_PERCENTAGE) {
        return OidcUtils.hasReadTransferPriceDiscountRole(userInformation)
    } else if (column.field === LineItemProperties.TRANSFER_PRICE_DISCOUNT_ACCUMULATED_PERCENTAGE) {
        return OidcUtils.hasReadTransferPriceDiscountRole(userInformation)
    } else if (column.field === LineItemProperties.TRANSFER_PRICE_DISCOUNTED_UNIT_PRICE) {
        return OidcUtils.hasReadTransferPriceDiscountRole(userInformation)
    } else if (column.field === LineItemProperties.TRANSFER_PRICE_DISCOUNTED_TOTAL_PRICE) {
        return OidcUtils.hasReadTransferPriceDiscountRole(userInformation)
    } else {
        return true
    }
}
