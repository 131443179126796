import { createTheme } from "@mui/material/styles"

const theme = createTheme({
    palette: {
        primary: {
            main: "#ff000f",
            light: "#ff999f"
        },
        secondary: {
            main: "#000000",
            light: "#a5a5a5"
        },
        common: {
            black: "#000000",
            white: "#ffffff"
        },
        background: {
            default: "#f5f5f5",
            paper: "#ffffff"
        },
        divider: "#dbdbdb",
        text: {
            primary: "#000000",
            secondary: "#696969",
            disabled: "#a5a5a5"
        },
        success: {
            main: "#21a67a"
        },
        warning: {
            main: "#ffa200"
        },
        info: {
            main: "#0000cc"
        }
    },
    typography: {
        fontFamily: ['"ABBVoice"', "Helvetica", "Roboto"].join(","),
        h1: {
            fontSize: "4em",
            fontWeight: "bolder"
        },
        h2: {
            fontSize: "2em",
            fontWeight: "bold"
        },
        h3: {
            fontSize: "1.2em",
            fontWeight: "bold"
        },
        h4: {
            fontSize: "0.8em",
            fontWeight: "bold"
        }
    },
    components: {
        MuiAlert: {
            styleOverrides: {
                root: ({ theme }) => ({
                    color: theme.palette.common.white,
                    "& .MuiAlert-icon": {
                        color: theme.palette.common.white
                    }
                }),
                standardSuccess: ({ theme }) => ({
                    backgroundColor: theme.palette.success.main
                }),
                filledSuccess: ({ theme }) => ({
                    backgroundColor: theme.palette.success.main
                }),
                outlinedSuccess: ({ theme }) => ({
                    backgroundColor: theme.palette.success.main
                }),
                standardWarning: ({ theme }) => ({
                    backgroundColor: theme.palette.warning.main
                }),
                filledWarning: ({ theme }) => ({
                    backgroundColor: theme.palette.warning.main
                }),
                outlinedWarning: ({ theme }) => ({
                    backgroundColor: theme.palette.warning.main
                }),
                standardError: ({ theme }) => ({
                    backgroundColor: theme.palette.primary.main
                }),
                filledError: ({ theme }) => ({
                    backgroundColor: theme.palette.primary.main
                }),
                outlinedError: ({ theme }) => ({
                    backgroundColor: theme.palette.primary.main
                }),
                standardInfo: ({ theme }) => ({
                    backgroundColor: theme.palette.info.main
                }),
                filledInfo: ({ theme }) => ({
                    backgroundColor: theme.palette.info.main
                }),
                outlinedInfo: ({ theme }) => ({
                    backgroundColor: theme.palette.info.main
                })
            }
        },
        MuiCssBaseline: {
            styleOverrides: `
      @font-face {
        font-family:'ABBVoice';
            src:url('static/fonts/abbvoice/ABBvoice_W_Rg.eot');
            src:url('static/fonts/abbvoice/ABBvoice_W_Rg.eot?#iefix') format('embedded-opentype'),
                url('static/fonts/abbvoice/ABBvoice_W_Rg.woff2') format('woff2'),
                url('static/fonts/abbvoice/ABBvoice_W_Rg.woff') format('woff')
      }
      
      html,
      body,
      #root {
        height: 100%;
      }
      `
        },
        MuiCard: {
            styleOverrides: {
                root: ({ theme }) => ({
                    borderRadius: 12,
                    boxShadow: "none",
                    display: "flex",
                    flexDirection: "column",
                    padding: 16,
                    transition: `box-shadow ${theme.transitions.duration.complex}ms`,
                    ":hover": {
                        boxShadow: theme.shadows.at(5)
                    },
                    ":active": {
                        opacity: 0.7
                    }
                })
            }
        },
        MuiFormControl: {
            defaultProps: {
                color: "secondary"
            },
            styleOverrides: {
                root: ({ theme, ownerState }) => ({
                    label: {
                        color: theme.palette.text.primary,
                        fontSize: theme.typography.button.fontSize,
                        fontWeight: theme.typography.fontWeightBold,
                        transform: "none",
                        position: "inherit",
                        marginBottom: 12,
                        display: ownerState.hiddenLabel ? "none !important" : "inherit"
                    },
                    "label.Mui-focused": {
                        color: theme.palette.text.primary
                    },
                    input: {
                        zIndex: 1,
                        color: theme.palette.text.primary,
                        "&::placeholder": {
                            color: theme.palette.text.disabled,
                            opacity: 1
                        }
                    },
                    ".MuiSelect-select": {
                        zIndex: 1
                    },
                    svg: {
                        zIndex: 1
                    },
                    ".MuiFormLabel-root": {
                        display: "flex",
                        alignItem: "center",
                        svg: {
                            position: "absolute",
                            right: 0
                        }
                    },
                    fieldset: {
                        backgroundColor: theme.palette.common.white,
                        zIndex: 0,
                        borderRadius: 4,
                        borderWidth: 2,
                        borderColor: theme.palette.divider,
                        top: 0,
                        legend: {
                            display: "none"
                        }
                    }
                })
            }
        },
        MuiTextField: {
            defaultProps: {
                color: "secondary",
                size: "small",
                fullWidth: true
            }
        },
        MuiInputLabel: {
            defaultProps: {
                shrink: true
            }
        },
        MuiSelect: {
            defaultProps: {
                size: "small"
            }
        },
        MuiButton: {
            styleOverrides: {
                root: ({ ownerState, theme }) => {
                    let color = (ownerState.sx as any)?.backgroundColor
                    if (!color && ownerState.color === "primary") color = theme.palette.primary.main
                    if (!color && ownerState.color === "secondary") color = theme.palette.secondary.main
                    if (ownerState.disabled) color = theme.palette.divider
                    return {
                        backgroundColor: ownerState.variant === "contained" ? color + "!important" : "transparent",
                        borderRadius: 30,
                        boxShadow: "none",
                        minWidth: 0,
                        textTransform: "none",
                        ":hover": {
                            backgroundColor: ownerState.variant === "contained" ? color + "!important" : "transparent",
                            boxShadow: 5,
                            textDecoration: ownerState.variant === "text" ? "underline" : "none"
                        }
                    }
                }
            }
        },
        MuiTooltip: {
            defaultProps: {
                arrow: true
            },
            styleOverrides: {
                tooltip: {
                    backgroundColor: "white",
                    color: "#0a0a0a",
                    border: "1px solid #696969"
                },
                arrow: {
                    color: "white",
                    "&:before": {
                        border: "1px solid #696969"
                    }
                }
            }
        },
        MuiBadge: {
            defaultProps: {
                overlap: "circular"
            },
            styleOverrides: {
                badge: props => ({
                    padding: 0,
                    backgroundColor:
                        props.ownerState.color && props.ownerState.color !== "default" ? props.theme.palette[props.ownerState.color].main : "white",
                    transform: "scale(0.70) translate(70%, -70%)"
                })
            }
        }
    }
})

export default theme
