import { BaseQueryApi } from "@reduxjs/toolkit/dist/query/baseQueryTypes"
import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../translations/TranslationKeys"
import { AddLineItemsArgs } from "../sales.api.types"
import SnackbarSlice from "../../snackbar/snackbar.slice"
import { useAppDispatch } from "../../../store/store"
import { SalesService, UpdateServerResponse } from "@encoway/sales-api-js-client"
import { StringLineItemProperties } from "../sales.constants"
import { AbbLineItem } from "../sales.types"

export async function addItemsQuery(args: AddLineItemsArgs, salesService: SalesService, api: BaseQueryApi) {
    const response = await salesService.custom.call("/lineItem/additems", args)
    handleAddItemsResponse(response, args, api.dispatch)
    return response
}

function handleAddItemsResponse(response: UpdateServerResponse, args: AddLineItemsArgs, dispatch: ReturnType<typeof useAppDispatch>) {
    const translationKeys = getTranslationKeys(args)
    const addedItemsWithValidationProblems = getAddedItemsWithHighSeverityValidationProblems(response)
    if (addedItemsWithValidationProblems) {
        const errorMessage = L10n.format(translationKeys.error, { addedItemsWithValidationProblems })
        dispatch(SnackbarSlice.actions.open({ message: errorMessage, severity: "error" }))
    } else {
        dispatch(SnackbarSlice.actions.open({ message: L10n.format(translationKeys.success), severity: "success" }))
    }
}

function getTranslationKeys(args: AddLineItemsArgs) {
    const key = args.articlesToInsert.length === 1 ? "single" : "multiple"
    return {
        success: TranslationKeys.pages.project.catalog.productSelection[key].addToCompositionSuccessMessage,
        error: TranslationKeys.pages.project.catalog.productSelection[key].addToCompositionErrorMessage
    }
}

function getAddedItemsWithHighSeverityValidationProblems(updateServerResponse: UpdateServerResponse): string {
    return getAddedItems(updateServerResponse)
        .filter(hasHighSeverityValidationProblems)
        .map(item => "'" + item.properties[StringLineItemProperties.CONFIGURATION_NAME] + "'")
        .join(", ")
}

function hasHighSeverityValidationProblems(lineItem: AbbLineItem) {
    return lineItem.properties.validationProblems?.flat().some(validationProblem => validationProblem.severity === "HIGH") ?? false
}

function getAddedItems(updateServerResponse: UpdateServerResponse): AbbLineItem[] {
    return Object.values(updateServerResponse.addedChildLineItems).flat()
}
