import { Section, SectionProps } from "@encoway/cui-configurator-components"
import InvisibleCuiContainers from "../../constants/InvisibleCuiContainers"
import { AbbHorizontalSectionStyles, AbbSectionStyles } from "./AbbSection.styles"
import { ContentBlockIds } from "../../../../../../features/catalog/catalog.constants"
import { ContainerTO } from "@encoway/c-services-js-client"
import CuiUtils from "../../utils/CuiUtils"

const LAYOUT_SECTIONS = [ContentBlockIds.HorizontalLayout, ContentBlockIds.VerticalLayout]

const shouldDisplayLabel = (container: ContainerTO) =>
    container.parameters?.length > 0 || // default behavior
    LAYOUT_SECTIONS.includes(container.children.at(0)?.name as any)

function hasContent(container: ContainerTO) {
    return (
        CuiUtils.hasParameters(container) ||
        CuiUtils.walkContainers(
            container,
            c => !InvisibleCuiContainers.some(name => c.name.startsWith(name)),
            c => c
        ).some(CuiUtils.hasParameters)
    )
}

const AbbSection = (props: SectionProps) => {
    return InvisibleCuiContainers.some(c => props.data?.name.startsWith(c)) || !hasContent(props.data) ? null : (
        <Section
            {...props}
            hideLabel={LAYOUT_SECTIONS.includes(props.data.name as any)}
            shouldDisplayLabel={shouldDisplayLabel}
            styles={props.data.name === ContentBlockIds.HorizontalLayout ? AbbHorizontalSectionStyles : AbbSectionStyles}
            prefix=""
        />
    )
}

export default AbbSection
