import { ProductSelection } from "./ProductQuickSelection"
import { ArticleWithQuantity } from "../../../../../features/sales/sales.api.types"

export function selectionToArray(selection: ProductSelection): ArticleWithQuantity[] {
    const productSelectionAsList: ArticleWithQuantity[] = []
    Object.values(selection).map(productSelection =>
        Object.entries(productSelection).forEach(([articleId, quantity]) => productSelectionAsList.push({ articleId, quantity }))
    )
    return productSelectionAsList
}

export function flattenSelection(selection: ProductSelection) {
    return Object.values(selection).reduce((result, group) => ({ ...result, ...group }), {})
}

export function isEmptySelection(selection: ProductSelection) {
    return Object.values(flattenSelection(selection)).length === 0
}

export function updateSelection(prev: ProductSelection, productGroupId: string, productId: string, amount: number) {
    const result = { ...prev }
    if (!result[productGroupId]) {
        result[productGroupId] = {}
    }
    if (amount === 0) {
        delete result[productGroupId][productId]
    } else {
        result[productGroupId][productId] = amount
    }
    return result
}
