import { Product } from "@encoway/c-services-js-client"
import { Button, Card, Skeleton, Stack, Typography } from "@mui/material"
import { Add, Settings } from "@mui/icons-material"
import { L10n } from "@encoway/l10n"
import ProductCardStyles from "./ProductCard.styles"
import ABBLogo from "../../../../../assets/logo.svg"
import useTextBox from "../../../../../components/textBox/useTextBox"
import TextBox from "../../../../../components/textBox/TextBox"
import TranslationKeys from "../../../../../features/translations/TranslationKeys"
import Image from "../../../../../components/image/Image"
import { NewTabLink } from "../../../../../components/links/newTabLink/NewTabLink"
import { useAppSelector } from "../../../../../store/store"
import { getProductData } from "./ProductCard.utils"
import { useMemo } from "react"

interface ProductCardProps {
    product?: Product
    onConfigureClick?: (product: Product) => void
    onAddToCompositionClick?: (product: Product) => void
    disabled?: boolean
}

export const ProductCard = ({ product, onConfigureClick, onAddToCompositionClick, disabled = false }: ProductCardProps) => {
    const readOnly = useAppSelector(state => state.sales.salesDocumentReadOnly)
    const textBox = useTextBox()
    const productData = useMemo(() => (product ? getProductData(product) : undefined), [product])
    const sx = ProductCardStyles(!!productData?.isConfigurable, disabled)
    return (
        <Card data-cy={`ProductCard.Card.${productData?.id}`} component={Stack} spacing={2} sx={sx.card}>
            <Stack spacing={2} sx={sx.stack}>
                {productData ? (
                    <Image src={productData?.imageSrc} fallbackImageProps={{ src: ABBLogo, alt: "image of " + productData?.name, sx: sx.productImage }} />
                ) : (
                    <Skeleton variant="rounded" width="100%" height={200} />
                )}
                {productData ? (
                    <Typography data-cy={"Typography:ProductCard"} sx={sx.productName}>
                        {productData.name}
                    </Typography>
                ) : (
                    <Skeleton width="100%" sx={sx.productName} />
                )}
            </Stack>

            <TextBox
                text={productData?.shortText ?? ""}
                sx={sx.description}
                expand={textBox.isExpanded}
                onExpansionChange={textBox.setIsExpanded}
                characterLimit={100}
                buttonProps={{ sx: sx.expandDescriptionButton }}
            />

            <Stack spacing={2} sx={sx.stack}>
                <NewTabLink
                    text={L10n.format(TranslationKeys.pages.project.catalog.link)}
                    href={productData?.catalogLink}
                    disabled={disabled || !productData}
                />
                <Button
                    data-cy={"OpenConfigureButton"}
                    variant="contained"
                    size="small"
                    disabled={disabled || readOnly || !productData?.isConfigurable || !onConfigureClick}
                    startIcon={<Settings sx={sx.configureButtonIcon} />}
                    onClick={() => onConfigureClick?.(product!)}
                    sx={sx.configureButton}
                >
                    {L10n.format(TranslationKeys.pages.project.catalog.productSelection.configureButtonLabel)}
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    disabled={disabled || readOnly || !productData || !onAddToCompositionClick}
                    startIcon={<Add />}
                    onClick={() => onAddToCompositionClick?.(product!)}
                    sx={sx.addCompositionButton}
                >
                    {L10n.format(TranslationKeys.pages.project.catalog.productSelection.addToCompositionButtonLabel)}
                </Button>
            </Stack>
        </Card>
    )
}
