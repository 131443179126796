import { ThemeProvider } from "@mui/material/styles"
import theme from "./App.theme"
import { CssBaseline } from "@mui/material"
import { store } from "./store/store"
import { Provider } from "react-redux"
import { DndProvider } from "react-dnd"
import { TouchBackend } from "react-dnd-touch-backend"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { RouterProvider } from "react-router-dom"
import router from "./router/constants/router"
import { LicenseInfo } from "@mui/x-license-pro"

LicenseInfo.setLicenseKey("32c7abbb4486a90608663f9e3b79b565Tz04NTcxNSxFPTE3NDExOTg2MjYwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI=")

export default function App() {
    return (
        <DndProvider backend={TouchBackend} options={{ enableMouseEvents: true }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <Provider store={store}>
                        <RouterProvider router={router} />
                    </Provider>
                </ThemeProvider>
            </LocalizationProvider>
        </DndProvider>
    )
}
