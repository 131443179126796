import { GridValueFormatterParams } from "@mui/x-data-grid-pro"
import { Settings } from "../../settings"
import toFormattedString from "../../utils/toFormattedString"

export const dateFormatter = (params: GridValueFormatterParams<string>) => {
    return params.value ? new Date(params.value).toLocaleDateString(Settings.locale) : ""
}

export const numberFormatter = (params: GridValueFormatterParams<number>) => toFormattedString(params.value)

export const priceFormatter = (params: GridValueFormatterParams<number>) => {
    return params.value ? toFormattedString(params.value, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : ""
}

export const percentageFormatter = (params: GridValueFormatterParams<number | string>) => {
    if (typeof params.value === "number") {
        const value = params.value ?? 0
        return `${toFormattedString(value)} %`
    } else {
        return params.value
    }
}
