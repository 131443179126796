import { GridColDef } from "@mui/x-data-grid-pro"
import { ProductQuantityCell } from "./components/productQuantityCell/ProductQuantityCell"
import { ProductInformationCell } from "./components/productInformationCell/ProductInformationCell"
import TranslationKeys from "../../../../../../features/translations/TranslationKeys"

const ColumnFields = {
    QUANTITY: "quantity",
    PRODUCT_NAME: "name",
    INFO: "info"
} as const

export const ProductQuickSelectionColumns: GridColDef[] = [
    {
        field: ColumnFields.QUANTITY,
        headerName: TranslationKeys.pages.project.catalog.quickSelection.dataGrid.columnHeaderNames.quantity,
        width: 160,
        renderCell: ProductQuantityCell
    },
    {
        field: ColumnFields.PRODUCT_NAME,
        headerName: TranslationKeys.pages.project.catalog.quickSelection.dataGrid.columnHeaderNames.productName
    },
    {
        field: ColumnFields.INFO,
        headerName: TranslationKeys.pages.project.catalog.quickSelection.dataGrid.columnHeaderNames.info,
        maxWidth: 10,
        resizable: false,
        renderCell: ProductInformationCell
    }
]

export const ProductQuickSelectionColumnsToNotAutosize: string[] = [ColumnFields.QUANTITY, ColumnFields.INFO]
