import { GridRenderCellParams } from "@mui/x-data-grid-pro"
import Icon from "../../../../../../../components/icons/Icon"
import { FolderOutlined, Person, Settings, SpaceDashboardOutlined } from "@mui/icons-material"
import { Badge } from "@mui/material"
import { isFooter } from "../row/rowUtils"
import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../../../../../../features/translations/TranslationKeys"
import SalesUtils from "../../../../../../../features/sales/sales.utils"
import { useContext } from "react"
import { BomNode, LineItemDataGridContext } from "../../LineItemsDataGrid"
import theme from "../../../../../../../App.theme"
import { useAppSelector } from "../../../../../../../store/store"

export default function LineItemTypeCell(params: GridRenderCellParams<BomNode>) {
    const { onConfigure } = useContext(LineItemDataGridContext)!
    const readOnly = useAppSelector(state => state.sales.salesDocumentReadOnly)
    if (params.row.lineItem.properties.isCustomLineItem) {
        return (
            <Badge badgeContent={<Person />}>
                <Icon component={SpaceDashboardOutlined} tooltipProps={{ title: L10n.format(TranslationKeys.lineItem.types.customArticle) }} />
            </Badge>
        )
    } else if (params.row.lineItem.properties.configurable) {
        return (
            <Badge badgeContent={<Settings />}>
                <Icon
                    onClick={() => onConfigure(params.row.lineItem, readOnly)}
                    component={SpaceDashboardOutlined}
                    sx={{ cursor: "pointer", ":hover": { color: theme.palette.text.secondary } }}
                    tooltipProps={{ title: L10n.format(TranslationKeys.lineItem.types.configurableArticle) }}
                />
            </Badge>
        )
    } else if (params.row.lineItem.properties.isArticle) {
        return <Icon component={SpaceDashboardOutlined} tooltipProps={{ title: L10n.format(TranslationKeys.lineItem.types.usualArticle) }} />
    } else if (SalesUtils.lineItems.isFolder(params.row.lineItem) && !isFooter(params.row.lineItem)) {
        return <Icon component={FolderOutlined} tooltipProps={{ title: L10n.format(TranslationKeys.lineItem.types.folder) }} />
    } else {
        return null
    }
}
