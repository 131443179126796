import { Card, CardProps } from "@mui/material"
import ProjectInformation from "./projectInformation/ProjectInformation"
import ProjectActions from "./projectActions/ProjectActions"
import { AbbSalesDocumentEntity } from "../../../../../features/sales/sales.types"
import useOpenSalesDocumentHandler from "../../../../../features/sales/hooks/useOpenSalesDocumentHandler"
import LockedProjectDialog from "../../../../../components/project/lockedProjectDialog/LockedProjectDialog"

export interface RecentProjectsStackItemProps extends CardProps {
    project: AbbSalesDocumentEntity
}

export default function ProjectCard({ project, ...props }: Readonly<RecentProjectsStackItemProps>) {
    const openSalesDocumentHandler = useOpenSalesDocumentHandler()
    return (
        <>
            <Card {...props} onClick={() => openSalesDocumentHandler.open(project, false)}>
                <ProjectActions project={project} onOpenProject={openSalesDocumentHandler.open} />
                <ProjectInformation project={project} />
            </Card>

            <LockedProjectDialog
                open={!!openSalesDocumentHandler.lockedSalesDocument}
                projectCanBeOpened={openSalesDocumentHandler.lockedSalesDocumentCanBeOpened}
                lockedBy={openSalesDocumentHandler.lockedSalesDocument?.properties.quote_locked_by}
                onOpenLockedProject={openSalesDocumentHandler.openLockedSalesDocument}
                onCancelOpen={openSalesDocumentHandler.cancel}
            />
        </>
    )
}
