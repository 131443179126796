import { useMemo } from "react"
import { GenericInput, InputType } from "../../../components/forms/genericForm/GenericForm"
import { CatalogApi } from "../../catalog/catalog.api"
import { Characteristics } from "../../catalog/catalog.constants"
import { useUserInformation } from "../../oidc/hooks/useUserInformation"
import CatalogUtils from "../../catalog/catalog.utils"
import { SalesDocumentProperties } from "../sales.constants"
import { OidcUtils } from "../../oidc/oidc.utils"

export function useLocalSalesUnitInput(skip?: boolean): GenericInput {
    const localSalesUnit = CatalogApi.useCharacteristicQuery(Characteristics.LocalSalesUnit.id, { skip }).data
    const userInformation = useUserInformation()

    const localSalesUnits = useMemo(() => {
        const possibleValues = localSalesUnit ? CatalogUtils.getPossibleValues(localSalesUnit, true) : []
        return possibleValues
            .filter(lsu => userInformation && OidcUtils.getLsus(userInformation).includes(lsu.id))
            .map(lsu => ({ value: lsu.id, text: lsu.value as string }))
    }, [localSalesUnit, userInformation])

    return useMemo(
        () => ({
            type: InputType.Dropdown,
            id: SalesDocumentProperties.LOCAL_SALES_UNIT,
            label: localSalesUnit?.name ?? "",
            mandatory: true,
            values: localSalesUnits,
            disabled: !userInformation || !OidcUtils.hasUpdateProjectLsuRole(userInformation)
        }),
        [localSalesUnit, localSalesUnits, userInformation]
    )
}
