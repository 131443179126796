import theme from "../../App.theme"

export const ConfigurationPageStyles = {
    configurationTree: (showConfigurationTree: boolean) => ({
        flex: "0 0 auto",
        display: showConfigurationTree ? undefined : "none",
        maxWidth: "100%"
    }),
    visualization: (showVisualization: boolean) => ({
        flex: "1 0 auto",
        display: showVisualization ? undefined : "none"
    }),
    configuration: (showConfiguration: boolean, showVisualization: boolean) => ({
        flex: showVisualization ? "0 0 auto" : "1 0 auto",
        width: "360px",
        display: showConfiguration ? undefined : "none",
        overflow: "auto"
    }),
    container: {
        backgroundColor: theme.palette.background.default,
        marginTop: 2,
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        flexGrow: 1
    }
}
