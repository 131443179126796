import { GridActionsCellItem, GridRowParams } from "@mui/x-data-grid-pro"
import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../../../../../../features/translations/TranslationKeys"
import { ReadyState } from "@encoway/cui-application-components"
import { useContext } from "react"
import { BomNode, LineItemDataGridContext } from "../../LineItemsDataGrid"
import AssignmentIcon from "@mui/icons-material/Assignment"
import AutoStoriesIcon from "@mui/icons-material/AutoStories"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import DeleteIcon from "@mui/icons-material/Delete"
import ModeEditIcon from "@mui/icons-material/ModeEdit"
import Icon from "../../../../../../../components/icons/Icon"
import SalesApi from "../../../../../../../features/sales/sales.api"
import { useAppSelector } from "../../../../../../../store/store"

export default function ActionsCell(params: GridRowParams<BomNode>) {
    const { onConfigure, onDelete, onGenerateDocument } = useContext(LineItemDataGridContext)!
    const readOnly = useAppSelector(state => state.sales.salesDocumentReadOnly)
    const [duplicateLineItem] = SalesApi.useDuplicateLineItemMutation()
    return !params.row.lineItem.properties.isSubarticle
        ? [
              <GridActionsCellItem
                  key="editAction"
                  icon={<ModeEditIcon />}
                  disabled={!params.row.lineItem.properties.configurable || readOnly}
                  label={L10n.format(TranslationKeys.lineItem.actions.edit)}
                  onClick={() => onConfigure(params.row.lineItem)}
                  showInMenu
              />,
              <GridActionsCellItem
                  key="readAction"
                  icon={<AutoStoriesIcon />}
                  disabled={!params.row.lineItem.properties.configurable}
                  label={L10n.format(TranslationKeys.lineItem.actions.read)}
                  onClick={() => onConfigure(params.row.lineItem, true)}
                  showInMenu
              />,
              <GridActionsCellItem
                  key="printAction"
                  label={L10n.format(TranslationKeys.lineItem.actions.print)}
                  onClick={() => onGenerateDocument(params.row.lineItem)}
                  showInMenu
                  disabled={params.row.lineItem.properties.readystate !== ReadyState.Ready}
                  icon={
                      <Icon
                          component={AssignmentIcon}
                          tooltipProps={{
                              title:
                                  params.row.lineItem.properties.readystate === ReadyState.Ready
                                      ? ""
                                      : L10n.format(TranslationKeys.pages.configuration.generateDocumentsButton.disabledReason)
                          }}
                      />
                  }
              />,
              <GridActionsCellItem
                  key="duplicateAction"
                  icon={<ContentCopyIcon />}
                  label={L10n.format(TranslationKeys.lineItem.actions.duplicate)}
                  onClick={() => duplicateLineItem([[params.row.lineItem.lineItemId], params.row.parent?.lineItem.lineItemId, params.row.lineItem.lineItemId])}
                  disabled={readOnly || !params.row.lineItem.permission.includes("UPDATE")}
                  showInMenu
              />,
              <GridActionsCellItem
                  key="deleteAction"
                  icon={<DeleteIcon />}
                  label={L10n.format(TranslationKeys.lineItem.actions.delete)}
                  onClick={() => onDelete(params.row.lineItem)}
                  disabled={readOnly || !params.row.lineItem.permission.includes("DELETE")}
                  showInMenu
              />
          ]
        : []
}
