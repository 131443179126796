import { IPartialTheme, IProcessedStyleSet, IStyleSet } from "@fluentui/react/lib/Styling"
import { classNamesFunction, styled } from "@fluentui/react/lib/Utilities"
import React, { MutableRefObject, useEffect, useState } from "react"
import { Viewer, VisualEditor, VisualEditorProps } from "@encoway/visual-editor"
import { IVisualEditor, VisualizationStyles } from "@encoway/cui-application-components"
import { IVisualizationStyles, VisualizationProps } from "./AbbVisualization.types"
import { IStyleFunction } from "@fluentui/merge-styles"
import { getCustomContextMenuEntries, getToolbar, updateDoors } from "./AbbVisualization.utils"
import VisualizationSlice from "../../../../../../features/visualization/visualization.slice"
import { useAppDispatch, useAppSelector } from "../../../../../../store/store"
import { IVisualizationNodeExtended } from "../../../../../../features/visualization/visualization.types"
import { useArDialog } from "./useArDialog"
import { Button, Portal, Tooltip } from "@mui/material"
import AbbArDialog from "./AbbArDialog"
import { SalesSlice } from "../../../../../../features/sales/sales.slice"
import { ConfigurationPageContainerIds } from "../../../../ConfigurationPage.constants"
import { DoorIcon } from "@fluentui/react-icons-mdl2"
import TranslationKeys from "../../../../../../features/translations/TranslationKeys"
import { L10n } from "@encoway/l10n"

function IVisualization(props: Readonly<VisualizationProps>) {
    const { styles, theme } = props
    const stylesFunction = styles && typeof styles === "function" ? (styles as IStyleFunction<IPartialTheme, IStyleSet<IVisualizationStyles>>) : undefined
    const style = stylesFunction?.(theme as IPartialTheme)
    const visualEditorRef = props.visualEditorForwardRef ? (props.visualEditorForwardRef as MutableRefObject<IVisualEditor>) : React.createRef<IVisualEditor>()
    const classNames = classNamesFunction()(props.styles, props.theme) as IProcessedStyleSet<IVisualizationStyles>

    const hideVisualization = useAppSelector(state => state.sales.hideVisualization)
    const dispatch = useAppDispatch()
    const arDialog = useArDialog()
    const [doorsAreHidden, setDoorsAreHidden] = useState(false)
    const [toolbar, setToolbar] = useState<HTMLDivElement>()

    useEffect(() => {
        dispatch(VisualizationSlice.actions.setVisualization(props.visualization))
        dispatch(SalesSlice.actions.setVisualizationIsAvailable(true))
    }, [dispatch, props.visualization])

    useEffect(() => {
        const visualizationContainer = document.getElementById(ConfigurationPageContainerIds.VISUALIZATION) as HTMLDivElement
        const mutationObserver = new MutationObserver(() => {
            const tb = getToolbar(visualizationContainer)
            if (tb) {
                setToolbar(tb)
                mutationObserver.disconnect()
            }
        })
        mutationObserver.observe(visualizationContainer, { subtree: true, childList: true })
        return () => mutationObserver.disconnect()
    }, [arDialog.open])

    const onContextMenu: VisualEditorProps["onContextMenu"] = (node: object, dismissMenu: () => void, contextAnchor: object | null) => {
        if (contextAnchor) {
            const items = getCustomContextMenuEntries(node as IVisualizationNodeExtended, props.visualization, dismissMenu)
            return { items, replace: items.length > 0 }
        } else {
            return { items: [], replace: false }
        }
    }

    const visualProps = {
        className: classNames.visualEditor,
        settings: props.settings,
        locale: props.locale,
        visualization: props.visualization,
        eventBus: props.eventBus,
        ref: visualEditorRef,
        onContextMenu: onContextMenu, // CUSTOMIZING
        onReady: () => (props.onReady ? props.onReady(visualEditorRef) : visualEditorRef.current?.zoom()),
        style: style?.visualEditorDiv
    }

    const toggleDoors = () => {
        updateDoors(props.visualization, !doorsAreHidden)
        setDoorsAreHidden(prevState => !prevState)
    }

    return hideVisualization ? null : (
        <>
            <Portal container={() => document.getElementById(ConfigurationPageContainerIds.VISUALIZATION)}>
                <div className={classNames.root}>
                    {/**@ts-ignore**/}
                    {props.readOnly ? <Viewer {...visualProps} /> : <VisualEditor {...visualProps} />}
                </div>
            </Portal>
            {toolbar && (
                <Portal container={toolbar}>
                    <Button
                        variant="text"
                        onClick={arDialog.open}
                        disableRipple
                        sx={{
                            borderRadius: 0,
                            "&:hover": { backgroundColor: "rgb(243, 242, 241)", textDecoration: "none" },
                            "&:active": { backgroundColor: "rgb(237, 235, 233)" }
                        }}
                    >
                        AR
                    </Button>
                    <AbbArDialog
                        close={arDialog.close}
                        isOpen={arDialog.isOpen}
                        qr={arDialog.qr}
                        error={arDialog.error?.message}
                        isLoading={arDialog.isLoading}
                    />
                    <Tooltip title={L10n.format(TranslationKeys.pages.configuration.visualization.hideDoors)} placement="right">
                        <Button
                            variant="text"
                            onClick={toggleDoors}
                            disableRipple
                            sx={{
                                borderRadius: 0,
                                padding: "12px",
                                backgroundColor: doorsAreHidden ? "rgb(243, 242, 241)" : undefined,
                                "&:hover": { backgroundColor: "rgb(243, 242, 241)", textDecoration: "none" },
                                "&:active": { backgroundColor: "rgb(237, 235, 233)" }
                            }}
                        >
                            <DoorIcon />
                        </Button>
                    </Tooltip>
                </Portal>
            )}
        </>
    )
}

export const AbbVisualization = styled(IVisualization, VisualizationStyles)
AbbVisualization.displayName = "Visualization"
