import { CSSProperties } from "react"

export const CellStyles: Record<string, CSSProperties> = {
    editIconDiv: {
        width: 0
    },
    editIcon: {
        position: "relative",
        top: "-6px",
        left: "-16px",
        transform: "scale(50%)"
    }
}
