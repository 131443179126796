import theme from "../../../../../App.theme"

export const FIRST_LEVEL_ITEM_CLASS_NAME = "first_level_item"

const LineItemsDataGridStyles = {
    "& .MuiDataGrid-pinnedRowsRenderZone > .MuiDataGrid-row": {
        backgroundColor: theme.palette.background.default + " !important",
        borderTopColor: theme.palette.common.black + " !important",
        borderBottomColor: theme.palette.common.black + " !important",
        borderTopWidth: "1px !important",
        borderBottomWidth: "1px !important"
    },
    [`.${FIRST_LEVEL_ITEM_CLASS_NAME}`]: {
        backgroundColor: theme.palette.grey["300"] + " !important"
    },
    "& .MuiDataGrid-row:hover": {
        cursor: "default"
    },
    "& .MuiDataGrid-cell--editable": {
        cursor: "pointer"
    }
}
export default LineItemsDataGridStyles
