import useDialog from "../../../../../components/dialog/useDialog"
import Dialog from "../../../../../components/dialog/Dialog"
import GenericForm from "../../../../../components/forms/genericForm/GenericForm"
import { useState } from "react"
import useNewArticleFormInputs from "./useNewArticleFormInputs"
import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../../../../features/translations/TranslationKeys"
import { AbbLineItemProperties } from "../../../../../features/sales/sales.types"
import SalesApi from "../../../../../features/sales/sales.api"
import { GenericFormUtils } from "../../../../../components/forms/genericForm/GenericForm.utils"

export default function NewArticleDialog(props: Readonly<ReturnType<typeof useDialog>>) {
    const [inputValues, setInputValues] = useState<Partial<AbbLineItemProperties>>({})
    const [addCustomLineItem] = SalesApi.useAddCustomLineItemMutation()
    const inputs = useNewArticleFormInputs()

    const updateInputValues = (property: keyof Partial<AbbLineItemProperties>, value: any) => {
        setInputValues(prevState => ({ ...prevState, [property]: value }))
    }

    const close = () => {
        props.close()
        setInputValues({})
    }

    const submit = () => {
        addCustomLineItem({ ...inputValues, name: inputValues.label }).then(close)
    }

    return (
        <Dialog
            withDividers
            title={L10n.format(TranslationKeys.lineItem.newArticle.dialog.title)}
            open={props.isOpen}
            onClose={close}
            defaultDialogActions={{
                onConfirm: submit,
                disableConfirm: !GenericFormUtils.isFormValid(inputs, inputValues),
                onCancel: close,
                cancelButtonLabel: L10n.format(TranslationKeys.lineItem.newArticle.dialog.cancelButtonLabel),
                confirmButtonLabel: L10n.format(TranslationKeys.lineItem.newArticle.dialog.submitButtonLabel)
            }}
        >
            <GenericForm
                inputs={inputs}
                values={inputValues}
                update={updateInputValues}
                validateInput={input => GenericFormUtils.validateInput(input, inputValues)}
                columns={1}
                spacing={2}
            />
        </Dialog>
    )
}
