import { DropTargetMonitor, XYCoord } from "react-dnd"
import { DragAndDropHoverPosition } from "./Row"
import { RefObject } from "react"
import { AbbLineItem, AbbSalesDocumentEntity } from "../../../../../../../features/sales/sales.types"
import { BomNode } from "../../LineItemsDataGrid"

/**
 * Checks if the given line item is a footer.
 * @param lineItem the line item to check.
 */
export const isFooter = (lineItem: AbbLineItem) => lineItem.isSalesDocument

export const getPreviousBomNode = (bomNode: BomNode, bomNodes: BomNode[]): BomNode | undefined => {
    const parentChildren = bomNode.parent?.children ?? bomNodes.filter(node => !node.parent)
    const index = parentChildren.findIndex(node => node.lineItem.lineItemId === bomNode.lineItem.lineItemId)
    return index > 0 ? parentChildren.at(index - 1) : undefined
}

/**
 * Gets the parent line item of a bom node. If a parent is not available, the sales document will be returned.
 * @param bomNode the bom node to get the parent from.
 * @param salesDocument the salesdocument of the bomNode.
 */
export const getParentFrom = (bomNode: BomNode, salesDocument: AbbSalesDocumentEntity): AbbLineItem | AbbSalesDocumentEntity => {
    return bomNode.parent?.lineItem ?? salesDocument
}

/**
 * Gets the hover position from the given dnd monitor and ref to the element.
 * @param monitor the dnd monitor.
 * @param ref the ref of the html element.
 */
export const getHoverPosition = (monitor: DropTargetMonitor, ref: RefObject<HTMLDivElement>): "top" | "bottom" | "center" | undefined => {
    const rect = ref.current?.getBoundingClientRect()
    const clientOffset = monitor.getClientOffset()
    return rect && clientOffset ? getDragAndDropHoverPosition(clientOffset, rect) : undefined
}

export const getDragAndDropHoverPosition = (clientOffset: XYCoord, elementRect: DOMRect): DragAndDropHoverPosition => {
    if (clientOffset.y >= elementRect.y && clientOffset.y <= elementRect.y + elementRect.height) {
        if (clientOffset.y < elementRect.y + elementRect.height / 4) {
            return "top"
        } else if (clientOffset.y > elementRect.y + elementRect.height - elementRect.height / 4) {
            return "bottom"
        } else {
            return "center"
        }
    }
}
