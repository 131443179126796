import { useEffect, useMemo, useState } from "react"
import { Alert, Box, Grid, Stack, Typography } from "@mui/material"
import ChecklistIcon from "@mui/icons-material/Checklist"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import EditNoteIcon from "@mui/icons-material/EditNote"
import ErrorIcon from "@mui/icons-material/Error"
import { L10n } from "@encoway/l10n"
import { NumberValue } from "@encoway/c-services-js-client"
import ProductSelectionStyles from "./ProductSelection.styles"
import ProductGroupCard, { ProductGroupCardSize } from "./productGroupCard/ProductGroupCard"
import { ProductStandardSelection } from "./productStandardSelection/ProductStandardSelection"
import { ProductQuickSelection } from "./productQuickSelection/ProductQuickSelection"
import useNavigate from "../../../../router/hooks/useNavigate"
import useParams from "../../../../router/hooks/useParams"
import TranslationKeys from "../../../../features/translations/TranslationKeys"
import Breadcrumbs, { Breadcrumb } from "../../../../components/breadcrumbs/Breadcrumbs"
import SearchInput from "../../../../components/input/searchInput/SearchInput"
import Tabs from "../../../../components/tabs/Tabs"
import { useSearchParams } from "react-router-dom"
import ProductFilters from "./productFilters/ProductFilters"
import CatalogUtils from "../../../../features/catalog/catalog.utils"
import { CatalogApi } from "../../../../features/catalog/catalog.api"
import { Characteristics, ProductGroupIds } from "../../../../features/catalog/catalog.constants"
import SalesApi from "../../../../features/sales/sales.api"
import { getBreadcrumbs, getProductSelection } from "./ProductSelection.utils"
import { ErrorAlert } from "../../../../features/error/components/errorAlert/ErrorAlert"

export const SEARCH_PARAM = "search"

export default function ProductSelection() {
    const [selectedTab, setSelectedTab] = useState(0)
    const [characteristicFilters, setCharacteristicFilters] = useState<Record<string, any>>({})

    const navigate = useNavigate()
    const params = useParams()
    const [searchParams, setSearchParams] = useSearchParams()
    const searchValue = searchParams.get(SEARCH_PARAM) ?? undefined

    const productGroupIds = useMemo(() => params.productGroupIds ?? [ProductGroupIds.Catalog], [params])
    const productGroupsQuery = CatalogApi.useProductGroupsQuery(productGroupIds)
    const productGroups = useMemo(() => productGroupsQuery.currentData ?? [], [productGroupsQuery.currentData])
    const currentProductGroupId = productGroupIds.at(-1)!
    const subGroupsQuery = CatalogApi.useSubGroupsQuery(currentProductGroupId)
    const productGroup = productGroups.find(productGroup => productGroup.id === currentProductGroupId)
    const quickSelectionCharacteristic = productGroup
        ? CatalogUtils.getCharacteristicValue<NumberValue>(productGroup, Characteristics.QuickSelectionEnabled.id)?.value
        : 0
    const quickSelectionIsAvailable = Boolean(quickSelectionCharacteristic)
    const catalogDefaultView = productGroup ? CatalogUtils.getCharacteristicValue<string>(productGroup, Characteristics.CatalogDefaultView.id) : undefined

    const productSelection = getProductSelection(currentProductGroupId, characteristicFilters, !!searchValue, searchValue)
    const productsQuery = CatalogApi.useProductsQuery(productSelection)
    const products = productsQuery.currentData?.products ?? []

    const salesDocument = SalesApi.endpoints.salesDocument.useQueryState().data!
    const productCategory = salesDocument?.properties.PRODUCT_CATEGORY

    const tabs = useMemo(
        () => [
            {
                cypressId: "tab.catalog",
                label: L10n.format(TranslationKeys.pages.project.catalog.tabs.catalog),
                icon: <ChecklistIcon />
            },
            {
                cypressId: "tab.quickSelection",
                label: L10n.format(TranslationKeys.pages.project.catalog.tabs.quickSelection),
                disabled: !quickSelectionIsAvailable,
                icon: <EditNoteIcon />
            }
        ],
        [quickSelectionIsAvailable]
    )

    useEffect(() => {
        setCharacteristicFilters({})
    }, [currentProductGroupId])

    useEffect(() => {
        setSelectedTab(quickSelectionIsAvailable && catalogDefaultView !== Characteristics.CatalogDefaultView.possibleValues.CATALOG ? 1 : 0)
    }, [catalogDefaultView, quickSelectionIsAvailable])

    const breadcrumbs: Breadcrumb[] = useMemo(() => {
        return getBreadcrumbs(productGroups, !!searchValue, salesDocument.salesDocumentId)
    }, [productGroups, salesDocument.salesDocumentId, searchValue])

    const onUpdateFilters = (characteristicId: string, value: any) => {
        setCharacteristicFilters(prevState => {
            const newState = { ...prevState }
            if (value) {
                newState[characteristicId] = value
            } else {
                delete newState[characteristicId]
            }
            return newState
        })
    }

    const quickSelectionProductGroups = useMemo(() => {
        return (productGroup ? [productGroup] : []).concat(subGroupsQuery.data?.productGroups ?? [])
    }, [productGroup, subGroupsQuery.data?.productGroups])

    const currentProductGroupIsCatalog = currentProductGroupId === ProductGroupIds.Catalog

    return (
        <Stack spacing={3}>
            {!currentProductGroupIsCatalog && <Breadcrumbs separator={<ChevronRightIcon fontSize="small" color="secondary" />} breadcrumbs={breadcrumbs} />}
            {productGroupsQuery.isError && <ErrorAlert error={productGroupsQuery.error.message} onRetry={productGroupsQuery.refetch} />}

            <SearchInput
                onSearch={searchValue => setSearchParams({ [SEARCH_PARAM]: searchValue })}
                placeholder={L10n.format(TranslationKeys.pages.project.catalog.searchInputPlaceholder)}
                keepSearchValue={!!searchValue}
            />

            {currentProductGroupIsCatalog && <Typography variant="h3">{L10n.format(TranslationKeys.pages.project.catalog.selectDriveSegmentTitle)}</Typography>}

            {productCategory && currentProductGroupIsCatalog && (
                <Alert severity="info" icon={<ErrorIcon color="warning" fontSize="small" />} sx={ProductSelectionStyles.warning}>
                    {L10n.format(TranslationKeys.pages.project.catalog.productCategoryHint)}
                </Alert>
            )}

            <Box>
                <Grid container spacing={1}>
                    {subGroupsQuery.isError && <ErrorAlert error={subGroupsQuery.error.message} onRetry={subGroupsQuery.refetch} />}
                    {subGroupsQuery.isFetching
                        ? [1, 2, 3].map(value => (
                              <Grid item key={value}>
                                  <ProductGroupCard size={productGroupIds.length > 1 ? ProductGroupCardSize.Default : ProductGroupCardSize.Big} />
                              </Grid>
                          ))
                        : null}
                    {subGroupsQuery.currentData?.productGroups.map(subGroup => {
                        const characteristicValue = CatalogUtils.getCharacteristicValue<string>(subGroup, Characteristics.ProductCategory.id)
                        return (
                            <Grid paddingTop={0} item key={subGroup.id}>
                                <ProductGroupCard
                                    productGroup={subGroup}
                                    onSelect={productGroup => navigate.toProductGroup(salesDocument.salesDocumentId, productGroupIds.concat([productGroup.id]))}
                                    size={productGroupIds.length > 1 ? ProductGroupCardSize.Default : ProductGroupCardSize.Big}
                                    disabled={
                                        (!!productCategory && !!characteristicValue && productCategory !== characteristicValue) || !subGroup.hasChildProducts
                                    }
                                />
                            </Grid>
                        )
                    })}
                </Grid>
            </Box>

            {productGroups.length > 1 || searchValue ? (
                <Tabs tabs={tabs} selectedTab={selectedTab} setSelectedTab={setSelectedTab} tabsStyles={ProductSelectionStyles.tabStyles} />
            ) : null}

            <ProductFilters
                productGroupId={currentProductGroupId}
                filterValues={characteristicFilters}
                onUpdateFilter={onUpdateFilters}
                aggregatedCharacteristicValues={productsQuery.currentData?.aggregatedCharacteristicValues ?? {}}
            />

            {selectedTab === 0 ? (
                <ProductStandardSelection
                    products={products}
                    characteristicFilters={characteristicFilters}
                    isLoading={productsQuery.isFetching && productGroupIds.length > 1}
                />
            ) : (
                <ProductQuickSelection productGroups={quickSelectionProductGroups} characteristicFilters={characteristicFilters} />
            )}
        </Stack>
    )
}
