import dimensioningApi from "../../../../../../../features/dimensioning/dimensioning.api"
import { CatalogApi } from "../../../../../../../features/catalog/catalog.api"
import { Characteristics } from "../../../../../../../features/catalog/catalog.constants"
import { DataGridProProps, GridRenderCellParams, GridRowClassNameParams } from "@mui/x-data-grid-pro"
import { DcsDimensioningResultEntry } from "../../../../../../../features/dimensioning/dimensioning.types"
import { Button } from "@mui/material"
import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../../../../../../features/translations/TranslationKeys"
import DataGrid from "../../../../../../../components/dataGrid/DataGrid"
import { AbbDimensioningDctColumns } from "./AbbDimensioningDctColumns"
import { ReactNode } from "react"
import { getIdsOfBestDcDimensioningResults, getIdsOfEnabledDcDimensioningResults, mapDimensioningDcColumns } from "../AbbDimensioningDc.utils"

interface AbbDimensioningDcsDataGridProps {
    configurationId: string
    parameterId: string
    renderProductNameCell: (params: GridRenderCellParams<DcsDimensioningResultEntry>) => ReactNode
    dataGridProps?: Partial<DataGridProProps>
}

export function AbbDimensioningDctDataGrid({ configurationId, parameterId, renderProductNameCell, dataGridProps }: Readonly<AbbDimensioningDcsDataGridProps>) {
    const dimensioningDctQuery = dimensioningApi.useDimensioningDctQuery({ configurationId, parameterId })
    const characteristics = [
        CatalogApi.useCharacteristicQuery(Characteristics.DcModuleFrameSize.id).data,
        CatalogApi.useCharacteristicQuery(Characteristics.DcModuleDimension.id).data,
        CatalogApi.useCharacteristicQuery(Characteristics.DcProductName.id).data,
        CatalogApi.useCharacteristicQuery(Characteristics.DcCurrent.id).data,
        CatalogApi.useCharacteristicQuery(Characteristics.DcMargin.id).data
    ]

    const highlighted = getIdsOfBestDcDimensioningResults(dimensioningDctQuery.data)
    const enabled = getIdsOfEnabledDcDimensioningResults(dimensioningDctQuery.data)

    const getRowClassName = (params: GridRowClassNameParams<DcsDimensioningResultEntry>) => (highlighted.includes(params.row.id) ? "highlighted" : "")

    return (
        <>
            <Button variant="contained" size="large" onClick={dimensioningDctQuery.refetch}>
                {L10n.format(TranslationKeys.pages.configuration.dimensioningButtonLabel)}
            </Button>
            <DataGrid
                {...dataGridProps}
                columns={mapDimensioningDcColumns(AbbDimensioningDctColumns, characteristics, renderProductNameCell)}
                rows={dimensioningDctQuery.data?.filter(r => enabled.includes(r.id)) ?? []}
                getRowClassName={getRowClassName}
                loading={dimensioningDctQuery.isFetching}
            />
        </>
    )
}
