import { useEffect } from "react"
import useParams from "../../router/hooks/useParams"
import useNavigate from "../../router/hooks/useNavigate"
import ConfigurationPage from "../configurationPage/ConfigurationPage"
import NavigateOptions from "../../router/constants/NavigateOptions"
import executeNavigationOptionAction from "../../router/utils/executeNavigationOptionAction"
import SalesApi from "../../features/sales/sales.api"
import { useAppSelector } from "../../store/store"

export default function OpenConfigurationPage() {
    const params = useParams()
    const navigate = useNavigate()

    const readOnly = useAppSelector(state => state.sales.configurationReadOnly)

    const salesDocument = SalesApi.endpoints.salesDocument.useQueryState().data!
    const [viewConfiguration] = SalesApi.useViewConfigurationMutation()
    const [openConfiguration] = SalesApi.useOpenConfigurationMutation()
    const [stopConfiguration] = SalesApi.useStopConfigurationMutation()
    const [triggerSaveConfiguration] = SalesApi.useSaveConfigurationMutation()

    useEffect(() => {
        if (readOnly) {
            viewConfiguration(params.lineItemId!)
        } else {
            openConfiguration(params.lineItemId!)
        }
        return () => {
            stopConfiguration()
        }
    }, [readOnly, params.lineItemId, viewConfiguration, openConfiguration, stopConfiguration])

    const saveConfiguration = async (navigateOption: NavigateOptions) => {
        await triggerSaveConfiguration()
        executeNavigationOptionAction(navigateOption, {
            [NavigateOptions.Navigate]: () => navigate.toProjectComposition(salesDocument.salesDocumentId),
            [NavigateOptions.Stay]: () => {
                openConfiguration(params.lineItemId!)
            },
            [NavigateOptions.DontNavigate]: () => null
        })
    }

    const cancelConfiguration = () => {
        navigate.toProjectComposition(salesDocument.salesDocumentId)
    }

    return <ConfigurationPage onSave={saveConfiguration} onStop={cancelConfiguration} />
}
