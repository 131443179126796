import { ParameterTO } from "@encoway/c-services-js-client"
import { Tooltip } from "@mui/material"
import { Label } from "@fluentui/react"
import AbbParameterLabelStyles from "./AbbParameterLabel.styles"
import CuiUtils, { NEGATIV_INFINITY_SYMBOL, POSITIV_INFINITY_SYMBOL } from "../../utils/CuiUtils"
import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../../../../../features/translations/TranslationKeys"
import Typography from "@mui/material/Typography"
import theme from "../../../../../../App.theme"

export function AbbParameterLabel(parameter: Readonly<ParameterTO>) {
    const formattedData: ParameterTO = CuiUtils.formatParameter(parameter)
    const getRangeText = () => {
        if (formattedData.originalMinValue !== NEGATIV_INFINITY_SYMBOL || formattedData.originalMaxValue !== POSITIV_INFINITY_SYMBOL) {
            return L10n.format(TranslationKeys.pages.configuration.abbInputField.rangeText, {
                minValue: formattedData.originalMinValue,
                maxValue: formattedData.originalMaxValue
            })
        }
    }
    return (
        <Tooltip title={<Label required={parameter.mandatory}>{parameter.translatedName}</Label>} placement="top">
            <div style={{ maxWidth: "100%" }}>
                <Label required={parameter.mandatory} styles={AbbParameterLabelStyles}>
                    {parameter.translatedName}
                </Label>
                {formattedData.viewPort === "inputField" && formattedData.valueType?.startsWith("RANGE_") && (
                    <Typography sx={{ position: "relative", height: 0, top: -8, fontSize: theme.typography.caption }}>{getRangeText()}</Typography>
                )}
            </div>
        </Tooltip>
    )
}
