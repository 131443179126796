import { MouseEventHandler, MutableRefObject, RefObject, TouchEventHandler, useRef } from "react"
import { ResizeBarStyles } from "./ResizeBar.styles"

interface ResizeBarProps {
    axis: "x" | "y"
    target: RefObject<HTMLDivElement>
    direction: 1 | -1
}

export const ResizeBar = (props: ResizeBarProps) => {
    const pos: MutableRefObject<number> = useRef(0)
    const axis = props.axis === "x" ? "x" : "y"
    const attribute = props.axis === "x" ? "width" : "height"

    const updateSize = (e: HTMLDivElement | null, newPos: number) => {
        const delta = props.direction * (pos.current - newPos)
        if (e !== null) {
            const size = parseInt(getComputedStyle(e, "")[attribute]) + delta
            e.style[attribute] = `${size}px`
        }
        pos.current = newPos
        window.dispatchEvent(new Event("resize"))
    }

    const resizeHandler = (event: MouseEvent) => {
        const newPos = event[axis]
        updateSize(props.target.current, newPos)
    }

    const onMouseDownHandler: MouseEventHandler<HTMLDivElement> = event => {
        pos.current = event.nativeEvent[axis]
        document.addEventListener("mousemove", resizeHandler, false)
        document.addEventListener("mouseup", () => document.removeEventListener("mousemove", resizeHandler, false), false)
    }

    const touchResizeHandler = (event: TouchEvent) => {
        const newPos = axis === "x" ? event.touches[0].clientX : event.touches[0].clientY
        updateSize(props.target.current, newPos)
    }

    const onTouchStartHandler: TouchEventHandler<HTMLDivElement> = event => {
        pos.current = axis === "x" ? event.touches[0].clientX : event.touches[0].clientY
        document.addEventListener("touchmove", touchResizeHandler, false)
        document.addEventListener("touchend", () => document.removeEventListener("touchmove", touchResizeHandler, false), false)
    }

    return <div style={ResizeBarStyles(axis)} onMouseDown={onMouseDownHandler} onTouchStart={onTouchStartHandler} />
}
