import useParams from "../../router/hooks/useParams"
import useNavigate from "../../router/hooks/useNavigate"
import { useEffect } from "react"
import ConfigurationPage from "../configurationPage/ConfigurationPage"
import NavigateOptions from "../../router/constants/NavigateOptions"
import executeNavigationOptionAction from "../../router/utils/executeNavigationOptionAction"
import SalesApi from "../../features/sales/sales.api"
import { getAddedLineItem } from "../../features/sales/sales.api.utils"
import SalesConfigurationUtils from "../../features/sales/utils/SalesConfigurationUtils"
import TranslationKeys from "../../features/translations/TranslationKeys"
import { L10n } from "@encoway/l10n"
import Dialog from "../../components/dialog/Dialog"
import useDialog from "../../components/dialog/useDialog"

export default function CreateConfigurationPage() {
    const salesDocument = SalesApi.endpoints.salesDocument.useQueryState().data!
    const [createConfiguration, { data }] = SalesApi.useCreateConfigurationMutation()
    const [stopConfiguration] = SalesApi.useStopConfigurationMutation()
    const [triggerAddConfiguration] = SalesApi.useAddConfigurationMutation()

    const dialog = useDialog()
    const params = useParams()
    const navigate = useNavigate()

    const invalidPresets = data ? SalesConfigurationUtils.readConfigurationResponse(data).invalidPresets : undefined
    const invalidPresetsData = invalidPresets ? SalesConfigurationUtils.readInvalidPresets(invalidPresets) : undefined

    const openDialog = dialog.open

    useEffect(() => {
        if (invalidPresets) {
            openDialog()
        }
    }, [invalidPresets, openDialog])

    useEffect(() => {
        createConfiguration(params.productId!)
        return () => {
            stopConfiguration()
        }
    }, [params.productId, createConfiguration, stopConfiguration])

    const saveConfiguration = async (navigateOption: NavigateOptions) => {
        const response = await triggerAddConfiguration().unwrap()
        const lineItem = getAddedLineItem(response)
        if (lineItem) {
            executeNavigationOptionAction(navigateOption, {
                [NavigateOptions.Navigate]: () => navigate.toProjectComposition(salesDocument.salesDocumentId),
                [NavigateOptions.Stay]: () => navigate.toConfiguration(salesDocument.salesDocumentId, lineItem.lineItemId),
                [NavigateOptions.DontNavigate]: () => null
            })
        }
    }

    return (
        <>
            <ConfigurationPage onSave={saveConfiguration} onStop={navigate.back} />
            <Dialog title={L10n.format(TranslationKeys.configuration.invalidPresetsMessageTitle)} onClose={dialog.close} open={dialog.isOpen}>
                <ul>{invalidPresetsData && Object.entries(invalidPresetsData).map(([key, value]) => <li key={key}>{key + ": " + value}</li>)}</ul>
            </Dialog>
        </>
    )
}
