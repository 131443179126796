import { View } from "../components/ActionBar/ActionBar"
import { AuthTokenProvider } from "@encoway/sales-showroom-auth"
import { Settings } from "../../../settings"
import { GuiTO, InternalTree } from "@encoway/c-services-js-client"

export class ConanService {
    static readonly fetchConfig = async (configurationId: string, view: View, tokenFunction: AuthTokenProvider) => {
        const response = await fetch(Settings.configuration.baseUrl + `/api/features/conan/configuration/${configurationId}/ui/${view}`, {
            method: "get",
            headers: {
                Authorization: "Bearer " + tokenFunction?.()
            }
        })
        return (await response.json()) as unknown as GuiTO | InternalTree
    }

    static readonly compare = async (configurationId: string, file: File, view: View, tokenFunction: AuthTokenProvider) => {
        const formData = new FormData()
        formData.append("File", file)
        return await fetch(Settings.configuration.baseUrl + `/api/features/conan/configuration/${configurationId}/compare`, {
            method: "post",
            headers: {
                Authorization: "Bearer " + tokenFunction?.()
            },
            body: formData
        })
    }

    static readonly stopComparison = async (configurationId: string, tokenFunction: AuthTokenProvider) => {
        await fetch(Settings.configuration.baseUrl + `/api/features/conan/configuration/${configurationId}/compare/stop`, {
            method: "post",
            headers: {
                Authorization: "Bearer " + tokenFunction?.()
            }
        })
    }
}
