import { generatePath } from "react-router-dom"
import { Button, Divider, Portal, Stack, Typography } from "@mui/material"
import { L10n } from "@encoway/l10n"
import { ReadyState } from "@encoway/cui-application-components"
import { ConfigurationHeaderStyles } from "./ConfigurationHeader.styles"
import BackToLink from "../../../../components/links/backToLink/BackToLink"
import TranslationKeys from "../../../../features/translations/TranslationKeys"
import GenerateDocumentsButton from "./generateDocumentsButton/GenerateDocumentsButton"
import SaveButton from "../../../../components/buttons/saveButton/SaveButton"
import theme from "../../../../App.theme"
import Container from "@mui/material/Container"
import RouterPaths from "../../../../router/constants/RouterPaths"
import { useAppDispatch, useAppSelector } from "../../../../store/store"
import NavigateOptions from "../../../../router/constants/NavigateOptions"
import useParams from "../../../../router/hooks/useParams"
import SalesApi from "../../../../features/sales/sales.api"
import useConfigurationService from "../../hooks/useConfigurationService"
import IconButton from "@mui/material/IconButton"
import FullscreenIcon from "@mui/icons-material/Fullscreen"
import BusySlice from "../../../../features/busy/busy.slice"
import { useFullscreen } from "../../../../hooks/useFullscreen"
import { useCallback } from "react"
import { AR_QR_CODE } from "./ConfigurationHeader.constants"
import { mapVisualizationImagesResult } from "./ConfigurationHeader.utils"
import { useLazyArQuery } from "../../hooks/useLazyArQuery"
import { useLazyRenderingsQuery } from "../../hooks/useLazyRenderingsQuery"
import { LayoutMenuButton } from "./LayoutMenuButton"
import { HeaderConstants } from "../../../rootPage/components/header/Header.constants"

interface ConfigurationHeaderProps {
    onSave: (navigateOption: NavigateOptions) => void
    onStop: (navigateOption: NavigateOptions) => void
}

export default function ConfigurationHeader({ onSave, onStop }: Readonly<ConfigurationHeaderProps>) {
    const readOnly = useAppSelector(state => state.sales.configurationReadOnly)
    const configurationIsSaved = useAppSelector(state => state.sales.configurationIsSaved)
    const params = useParams()
    const dispatch = useAppDispatch()
    const configurationService = useConfigurationService()
    const visualization = useAppSelector(state => state.visualization.visualization)
    const salesDocument = SalesApi.endpoints.salesDocument.useQueryState().data!
    const lineItem = SalesApi.useLineItemQuery(params.lineItemId!, { skip: !params.lineItemId || !params.salesDocumentId }).data
    const arQuery = useLazyArQuery()
    const renderingsQuery = useLazyRenderingsQuery()

    const fullscreenElementGetter = useCallback(() => document.getElementsByClassName("cui-container")[0] as HTMLElement, [])
    const fullscreen = useFullscreen(fullscreenElementGetter)

    const getConfigurationProperties = async () => {
        const promiseResult = await Promise.allSettled([arQuery.fetch(), renderingsQuery.fetch()])
        const imagesResult = promiseResult[1].status === "fulfilled" ? promiseResult[1].value.data : undefined
        const arQrCode = promiseResult[0].status === "fulfilled" ? promiseResult[0].value?.data?.qr?.split(",").at(1) : undefined
        return { [AR_QR_CODE]: arQrCode, ...mapVisualizationImagesResult(imagesResult) }
    }

    const save = async (navigateOption: NavigateOptions) => {
        if (visualization) {
            const configurationProperties = await getConfigurationProperties()
            dispatch(BusySlice.actions.setBusy(L10n.format(TranslationKeys.busy.visualization.save)))
            await configurationService!.configurationProperties(configurationProperties)
            dispatch(BusySlice.actions.setIdle(L10n.format(TranslationKeys.busy.visualization.save)))
        }
        onSave(navigateOption)
    }

    const handleCancelButtonClick = () => {
        onStop(NavigateOptions.Navigate)
    }

    return (
        <Portal container={() => document.getElementById(HeaderConstants.CONFIGURATION_HEADER)}>
            <Stack alignItems="center" sx={ConfigurationHeaderStyles.stack}>
                <Container maxWidth={false} sx={ConfigurationHeaderStyles.container}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} py={1}>
                        <BackToLink
                            text={salesDocument.properties.project_name}
                            route={generatePath(RouterPaths.PROJECT_DETAILS, { salesDocumentId: salesDocument.salesDocumentId })}
                        />
                        {readOnly && <Typography color={theme.palette.warning.main}>{L10n.format(TranslationKeys.common.readOnly)}</Typography>}
                        <Stack direction="row" spacing={2} alignItems="center" style={{ flexShrink: 0 }}>
                            <Button onClick={handleCancelButtonClick}>{L10n.format(TranslationKeys.pages.configuration.cancelButtonLabel)}</Button>
                            <IconButton onClick={fullscreen.activate}>
                                <FullscreenIcon />
                            </IconButton>
                            <GenerateDocumentsButton
                                lineItemId={lineItem?.lineItemId}
                                disabled={lineItem?.properties.readystate !== ReadyState.Ready || !configurationIsSaved}
                                disabledReason={L10n.format(L10n.format(TranslationKeys.pages.configuration.generateDocumentsButton.disabledReason))}
                            />
                            <SaveButton
                                label={L10n.format(TranslationKeys.pages.configuration.applyButton.label)}
                                disabled={configurationIsSaved || readOnly}
                                onClick={save}
                                shouldBlockNavigation={!configurationIsSaved}
                                shouldBlockUnload={!configurationIsSaved}
                                menuItems={[
                                    {
                                        text: L10n.format(TranslationKeys.pages.configuration.applyButton.stayOptionLabel),
                                        value: NavigateOptions.Stay,
                                        cypressId: "stayOptionLabel"
                                    },
                                    {
                                        text: L10n.format(TranslationKeys.pages.configuration.applyButton.navigateOptionLabel),
                                        value: NavigateOptions.Navigate,
                                        cypressId: "navigateOptionLabel"
                                    }
                                ]}
                            />
                            <LayoutMenuButton />
                        </Stack>
                    </Stack>
                </Container>
                <Divider sx={{ width: "100%", boxShadow: theme.shadows[1] }} />
            </Stack>
        </Portal>
    )
}
