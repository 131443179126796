import { useState } from "react"
import { L10n } from "@encoway/l10n"
import useDialog from "../../dialog/useDialog"
import useNavigate from "../../../router/hooks/useNavigate"
import useNewProjectFormInputs from "./useNewProjectFormInputs"
import Dialog from "../../dialog/Dialog"
import TranslationKeys from "../../../features/translations/TranslationKeys"
import GenericForm from "../../forms/genericForm/GenericForm"
import { SalesDocumentFilter } from "@encoway/sales-api-js-client"
import { ButtonPropsWithLabel } from "../../dialog/dialogActions/DialogActions"
import DialogStyles from "../../dialog/Dialog.styles"
import { generatePath } from "react-router-dom"
import RouterPaths from "../../../router/constants/RouterPaths"
import SalesApi from "../../../features/sales/sales.api"
import { SalesDocumentProperties } from "../../../features/sales/sales.constants"
import { AbbSalesDocumentProperties, AbbSalesDocumentProperty } from "../../../features/sales/sales.types"
import { GenericFormUtils } from "../../forms/genericForm/GenericForm.utils"

export default function NewProjectDialog(props: Readonly<ReturnType<typeof useDialog>>) {
    const [inputValues, setInputValues] = useState<Partial<AbbSalesDocumentProperties>>({})
    const projectsWithSalesForceOpportunityNumberExistDialog = useDialog()
    const [inputHasFocus, setInputHasFocus] = useState(false)

    const salesDocuments = SalesApi.useSalesDocumentsQuery(
        {
            filterConfig: new SalesDocumentFilter().add(
                SalesDocumentProperties.SALESFORCE_OPPORTUNITY_NUMBER,
                "=",
                inputValues.salesforce_opportunity_number ?? "",
                "string"
            )
        },
        { skip: !inputValues.salesforce_opportunity_number || !props.isOpen }
    )
    const [createSalesDocument] = SalesApi.useCreateSalesDocumentMutation()

    const updateInputValue = (property: AbbSalesDocumentProperty, value: string | number) => {
        setInputValues(prevState => ({ ...prevState, [property]: value }))
        setInputHasFocus(false)
    }

    const navigate = useNavigate()
    const inputs = useNewProjectFormInputs(!props.isOpen)

    const createNewProject = () => {
        projectsWithSalesForceOpportunityNumberExistDialog.close()
        createSalesDocument(inputValues)
            .unwrap()
            .then(createdSalesDocument => {
                props.close()
                navigate.toProjectDetails(createdSalesDocument.salesDocumentId)
            })
    }

    const submit = () => {
        if (
            inputValues.salesforce_opportunity_number &&
            !!salesDocuments.data?.data.find(
                salesDocument => salesDocument.properties.salesforce_opportunity_number === inputValues.salesforce_opportunity_number
            )
        ) {
            projectsWithSalesForceOpportunityNumberExistDialog.open()
            props.close()
        } else {
            createNewProject()
        }
    }

    const handleExistingProject = () => {
        projectsWithSalesForceOpportunityNumberExistDialog.close()
        props.close()
        navigate.withSearchParams(generatePath(RouterPaths.PROJECTS), {
            [SalesDocumentProperties.SALESFORCE_OPPORTUNITY_NUMBER]: inputValues.salesforce_opportunity_number!
        })
    }

    const existsOpportunityIdButton: ButtonPropsWithLabel = {
        label: TranslationKeys.project.newProject.exists.showExistingProjects,
        variant: "contained",
        onClick: handleExistingProject,
        sx: DialogStyles.blackButton
    }
    const createNewProjectButton: ButtonPropsWithLabel = {
        label: TranslationKeys.project.newProject.exists.createNewProject,
        variant: "contained",
        onClick: createNewProject,
        sx: DialogStyles.blackButton
    }

    return (
        <>
            <Dialog
                withDividers
                title={L10n.format(TranslationKeys.project.newProject.dialog.title)}
                open={props.isOpen}
                onClose={props.close}
                sx={{ ".MuiDialog-paper": { width: 400 } }}
                defaultDialogActions={{
                    onConfirm: submit,
                    disableConfirm: !GenericFormUtils.isFormValid(inputs, inputValues) || inputHasFocus,
                    onCancel: props.close
                }}
            >
                <GenericForm
                    inputs={inputs}
                    values={inputValues}
                    update={updateInputValue}
                    validateInput={input => GenericFormUtils.validateInput(input, inputValues[input.id])}
                    columns={1}
                    spacing={2}
                    onFocusInput={() => setInputHasFocus(true)}
                />
            </Dialog>
            <Dialog
                open={projectsWithSalesForceOpportunityNumberExistDialog.isOpen}
                onClose={projectsWithSalesForceOpportunityNumberExistDialog.close}
                title={L10n.format(TranslationKeys.project.newProject.exists.title)}
                sx={{ ".MuiDialog-paper": { width: 400 } }}
                message={L10n.format(TranslationKeys.project.newProject.exists.message)}
                customDialogActions={{
                    actions: [existsOpportunityIdButton, createNewProjectButton]
                }}
            />
        </>
    )
}
