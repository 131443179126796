import { DataGridPro, DataGridProProps, GRID_DEFAULT_LOCALE_TEXT } from "@mui/x-data-grid-pro"
import { DataGridStyles } from "./DataGrid.styles"
import { Cell } from "./components/cell/Cell"
import { NoRowsOverlay } from "./components/noRowsOverlay/NoRowsOverlay"
import { LoadingOverlay } from "./components/loadingOverlay/LoadingOverlay"
import { merge } from "lodash"

export default function DataGrid({ localeText, rows, slots, slotProps, sx, autosizeOptions, ...props }: Readonly<DataGridProProps>) {
    return (
        <DataGridPro
            data-cy="DataGrid"
            pageSizeOptions={[10, 20, 50]}
            autoHeight={true}
            disableRowSelectionOnClick
            {...props}
            localeText={{ ...GRID_DEFAULT_LOCALE_TEXT, ...localeText }}
            rows={rows}
            slots={{ loadingOverlay: LoadingOverlay, cell: Cell, noRowsOverlay: NoRowsOverlay, ...slots }}
            slotProps={merge({ row: { "data-cy": "row" } }, slotProps)}
            sx={[DataGridStyles, ...(Array.isArray(sx) ? sx : [sx])]}
            autosizeOptions={{ includeOutliers: true, includeHeaders: true, expand: true, ...autosizeOptions }}
        />
    )
}
