import { LinkedTree, LinkedTreeProps } from "@encoway/cui-configurator-components"
import AbbLinkedTreeStyles from "./AbbLinkedTree.styles"
import { Portal } from "@mui/material"
import { ConfigurationPageContainerIds } from "../../../../ConfigurationPage.constants"

export default function AbbLinkedTree(props: Readonly<LinkedTreeProps>) {
    return (
        <Portal container={() => document.getElementById(ConfigurationPageContainerIds.CONFIGURATION_TREE)}>
            <LinkedTree {...props} styles={AbbLinkedTreeStyles} />
        </Portal>
    )
}
