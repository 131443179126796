import { GridColDef, GridSortModel, useGridApiRef } from "@mui/x-data-grid-pro"
import ProductQuickSelectionDataGridStyles from "./ProductQuickSelectionDataGrid.styles"
import DataGrid from "../../../../../../components/dataGrid/DataGrid"
import CatalogUtils from "../../../../../../features/catalog/catalog.utils"
import { useSearchParams } from "react-router-dom"
import { SEARCH_PARAM } from "../../ProductSelection"
import { CatalogApi } from "../../../../../../features/catalog/catalog.api"
import { ViewIds } from "../../../../../../features/catalog/catalog.constants"
import { createContext, memo, useEffect, useMemo, useState } from "react"
import { ProductQuickSelectionColumns, ProductQuickSelectionColumnsToNotAutosize } from "./ProductQuickSelectionDataGrid.constants"
import { getCharacteristicColumns, getRowClassName, getRowId } from "./ProductQuickSelectionDataGrid.utils"
import { L10n } from "@encoway/l10n"
import { getProductSelection } from "../../ProductSelection.utils"

interface ProductQuickSelectionDataGridProps {
    productGroupId: string
    characteristicFilters: Record<string, any>
    updateProductQuantity: (productGroupId: string, productId: string, quantity: number) => void
    selectedProducts: Record<string, number>
}

export const ProductQuickSelectionDataGridContext = createContext<ProductQuickSelectionDataGridProps | undefined>(undefined)

function ProductQuickSelectionDataGrid(props: Readonly<ProductQuickSelectionDataGridProps>) {
    const [sortModel, setSortModel] = useState<GridSortModel>()
    const [searchParams] = useSearchParams()
    const searchValue = searchParams.get(SEARCH_PARAM) ?? undefined
    const gridApiRef = useGridApiRef()

    const productSelection = getProductSelection(props.productGroupId, props.characteristicFilters, false, searchValue, ViewIds.QUICK_SELECTION)
    const productsQuery = CatalogApi.useProductsQuery(productSelection)

    const characteristics = useMemo(() => {
        return productsQuery.currentData ? CatalogUtils.sortCharacteristicsByView(productsQuery.currentData, ViewIds.QUICK_SELECTION).characteristics : []
    }, [productsQuery.currentData])

    useEffect(() => {
        setSortModel(characteristics.map(characteristic => ({ field: characteristic.id, sort: "asc" })))
    }, [characteristics])

    const columns = useMemo(() => {
        const copiedColumns: GridColDef[] = ProductQuickSelectionColumns.map(column => ({ ...column, headerName: L10n.format(column.headerName!) }))
        copiedColumns.splice(2, 0, ...getCharacteristicColumns(characteristics))
        return copiedColumns
    }, [characteristics])

    const autosizeColumns = useMemo(() => {
        return columns.map(column => column.field).filter(field => !ProductQuickSelectionColumnsToNotAutosize.includes(field))
    }, [columns])

    useEffect(() => {
        gridApiRef.current.autosizeColumns({
            includeOutliers: true,
            expand: true,
            includeHeaders: false,
            columns: autosizeColumns
        })
    }, [autosizeColumns, columns, gridApiRef])

    return (
        <ProductQuickSelectionDataGridContext.Provider value={props}>
            <DataGrid
                apiRef={gridApiRef}
                sx={ProductQuickSelectionDataGridStyles}
                rows={productsQuery.currentData?.products ?? []}
                getRowId={getRowId}
                getRowClassName={getRowClassName}
                columns={columns}
                hideFooter
                sortModel={sortModel}
                onSortModelChange={setSortModel}
                loading={productsQuery.isFetching}
                slotProps={{ noRowsOverlay: { error: productsQuery.error?.message, onRetry: productsQuery.refetch } }}
                autosizeOptions={{ columns: autosizeColumns }}
            />
        </ProductQuickSelectionDataGridContext.Provider>
    )
}

export default memo(ProductQuickSelectionDataGrid)
