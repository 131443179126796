import { DropdownProps } from "@encoway/cui-configurator-components"
import { useEffect } from "react"
import { Characteristics } from "../../../../../../features/catalog/catalog.constants"
import { useAppDispatch } from "../../../../../../store/store"
import { SalesSlice } from "../../../../../../features/sales/sales.slice"

export function AbbDefaultLayout(props: DropdownProps<any>) {
    const dispatch = useAppDispatch()

    useEffect(() => {
        const layout = props.data.selectedValues?.at(0)?.value
        if (layout === Characteristics.CuiDefaultLayout.possibleValues.FocusVisualization) {
            dispatch(SalesSlice.actions.changeConfigurationLayout({ hideConfigurationTree: true, hideConfiguration: false, hideVisualization: false }))
        } else if (layout === Characteristics.CuiDefaultLayout.possibleValues.OnlyConfiguration) {
            dispatch(SalesSlice.actions.changeConfigurationLayout({ hideConfigurationTree: true, hideConfiguration: false, hideVisualization: true }))
        } else if (layout === Characteristics.CuiDefaultLayout.possibleValues.FocusConfiguration) {
            dispatch(SalesSlice.actions.changeConfigurationLayout({ hideConfigurationTree: false, hideConfiguration: false, hideVisualization: true }))
        }
    }, [dispatch, props.data.selectedValues])

    return null
}
