export const Characteristics = {
    ProductImage: { id: "Product_image" },
    CatalogLink: { id: "CATALOGUE_LINK" },
    Date: { id: "Date" },
    LongText: { id: "long_text_gui" },
    ShortText: { id: "short_text_gui" },
    GraphDefinition: { id: "GraphDefinition" },
    DocumentTypes: { id: "documentTypes" },
    Translations: { id: "translationsFile" },
    IndustrySegment: { id: "INDUSTRY_SEGMENT" },
    DestinationCountry: { id: "DESTINATION_COUNTRY" },
    ProjectCurrency: { id: "PROJECT_CURRENCY" },
    QuickSelectionEnabled: { id: "QUICKSELECTION_ENABLED" },
    LvFrameSize: { id: "LV_FRAME_SIZE" },
    ProductCategory: { id: "PRODUCT_CATEGORY" },
    LocalSalesUnit: { id: "LOCAL_SALES_UNIT" },
    ConfigurableProductReferenceModel: { id: "CONFIG_REF_MODEL" },
    ConfigurableProductReferencePreset: { id: "CONFIG_REF_PRESET" },
    DcModuleFrameSize: { id: "DC_ModuleFrameSize" },
    DcThyristorMaxTemp: { id: "DC_ThyristorMaxTemp" },
    DcModuleDimension: { id: "DC_ModuleDimension" },
    DcThyristorTemp: { id: "DC_ThyristorTemp" },
    DcCurrent: { id: "DC_Current" },
    DcMargin: { id: "DC_Margin" },
    DcProductName: { id: "DC_ProductName" },
    VisualizationSettings: { id: "visualizationSettings" },
    RetentionTime: { id: "retentionTime" },
    ArPlacement: { id: "arPlacement" },
    ArScaleFactor: { id: "arScaleFactor" },
    AlertColor: { id: "ANNOUNCEMENT_COLOR" },
    AlertActive: { id: "ANNOUNCEMENT_ACTIVE" },
    CatalogDefaultView: {
        id: "CATALOGUE_DEFAULT_VIEW",
        possibleValues: {
            CATALOG: "CATALOG",
            QUICK_SELECTION: "QUICKSELECTION"
        }
    },
    HideConfigurationTree: { id: "HIDE_CONFIGURATION_TREE" },
    Orientation: {
        id: "ORIENTATION",
        possibleValues: {
            HORIZONTAL: "HORIZONTAL",
            VERTICAL: "VERTICAL"
        }
    },
    CuiDefaultLayout: {
        id: "CONFIGURATION_UI_DEFAULT_LAYOUT",
        possibleValues: {
            FocusVisualization: "FOCUS_VISUALIZATION",
            FocusConfiguration: "FOCUS_CONFIGURATION",
            OnlyConfiguration: "ONLY_CONFIGURATION"
        }
    }
} as const

export const ProductGroupIds = {
    Catalog: "_CATALOGUE",
    News: "NEWS",
    Announcements: "announcements"
} as const

export const ContentBlockIds = {
    Translations: "application_texts",
    DocumentTypes: "document_types",
    ProjectDocumentTypes: "document_types_project",
    Announcement: "announcement",
    HorizontalLayout: "LAYOUT_HORIZONTAL",
    VerticalLayout: "LAYOUT_VERTICAL"
} as const

export const ViewIds = {
    QUICK_SELECTION: "abb.quickselectionview",
    SALES_FILTER: "encoway.salesFilterView"
} as const
