import useDialog from "../../../../../../components/dialog/useDialog"
import { useLazyArQuery } from "../../../../hooks/useLazyArQuery"

export function useArDialog() {
    const dialog = useDialog()
    const arQuery = useLazyArQuery()

    const openDialog = () => {
        arQuery.fetch()
        dialog.open()
    }

    return {
        open: openDialog,
        close: dialog.close,
        isOpen: dialog.isOpen,
        qr: arQuery.result.data?.qr,
        error: arQuery.result.error,
        isLoading: arQuery.result.isLoading
    }
}
