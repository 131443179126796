import ConfigurationHeader from "./components/configurationHeader/ConfigurationHeader"
import Cui from "./components/cui/Cui"
import NavigateOptions from "../../router/constants/NavigateOptions"
import { MainContainer } from "../../components/mainContainer/MainContainer"
import { useAppSelector } from "../../store/store"
import Box from "@mui/material/Box"
import { Stack } from "@mui/material"
import React, { useRef } from "react"
import { ConfigurationPageStyles } from "./ConfigurationPage.styles"
import { ConfigurationPageContainerIds } from "./ConfigurationPage.constants"
import { ResizeBar } from "../../components/resizeBar/ResizeBar"
import useConfigurationProduct from "../../features/sales/hooks/useConfigurationProduct"
import CatalogUtils from "../../features/catalog/catalog.utils"
import { NumberValue } from "@encoway/c-services-js-client"
import { Characteristics } from "../../features/catalog/catalog.constants"

interface ConfigurationPageProps {
    onSave: (navigateOption: NavigateOptions) => void
    onStop: (navigateOption: NavigateOptions) => void
}

export default function ConfigurationPage({ onSave, onStop }: Readonly<ConfigurationPageProps>) {
    const configurationTreeRef = useRef<HTMLDivElement>(null)
    const configurationRef = useRef<HTMLDivElement>(null)

    const product = useConfigurationProduct()
    const hideTreeCharacteristicValue = product
        ? CatalogUtils.getCharacteristicValue<NumberValue>(product, Characteristics.HideConfigurationTree.id)?.value === 1
        : false

    const configurationTreeIsAvailable = useAppSelector(state => state.sales.configurationTreeIsAvailable)
    const visualizationIsAvailable = useAppSelector(state => state.sales.visualizationIsAvailable)
    const hideConfigurationTree = useAppSelector(state => state.sales.hideConfigurationTree)
    const hideVisualization = useAppSelector(state => state.sales.hideVisualization)
    const hideConfiguration = useAppSelector(state => state.sales.hideConfiguration)

    const showConfigurationTree = configurationTreeIsAvailable && !hideConfigurationTree && !hideTreeCharacteristicValue
    const showVisualization = visualizationIsAvailable && !hideVisualization
    const showConfiguration = !hideConfiguration
    const showLeftResizeBar = showConfigurationTree && (showVisualization || showConfiguration)
    const showRightResizeBar = showConfiguration && showVisualization

    return (
        <>
            <ConfigurationHeader onSave={onSave} onStop={onStop} />
            <MainContainer sx={ConfigurationPageStyles.container} className="cui-container">
                <Stack direction="row" spacing={1} sx={{ height: "100%" }}>
                    <Box
                        id={ConfigurationPageContainerIds.CONFIGURATION_TREE}
                        ref={configurationTreeRef}
                        sx={ConfigurationPageStyles.configurationTree(showConfigurationTree)}
                        data-cy="AbbLinkedTree"
                    />
                    {showLeftResizeBar && <ResizeBar target={configurationTreeRef} direction={-1} axis="x" />}
                    <Box id={ConfigurationPageContainerIds.VISUALIZATION} sx={ConfigurationPageStyles.visualization(showVisualization)} />
                    {showRightResizeBar && <ResizeBar target={configurationRef} direction={1} axis="x" />}
                    <Box
                        id={ConfigurationPageContainerIds.CONFIGURATION}
                        ref={configurationRef}
                        sx={ConfigurationPageStyles.configuration(showConfiguration, showVisualization)}
                    ></Box>
                </Stack>
                <Cui />
            </MainContainer>
        </>
    )
}
