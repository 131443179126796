import dimensioningApi from "../../../../../../../features/dimensioning/dimensioning.api"
import { CatalogApi } from "../../../../../../../features/catalog/catalog.api"
import { Characteristics } from "../../../../../../../features/catalog/catalog.constants"
import { DataGridProProps, GridRenderCellParams, GridRowClassNameParams } from "@mui/x-data-grid-pro"
import { DcsDimensioningResultEntry } from "../../../../../../../features/dimensioning/dimensioning.types"
import { Button } from "@mui/material"
import { AbbDimensioningDcsColumns } from "./AbbDimensioningDcsColumns"
import DataGrid from "../../../../../../../components/dataGrid/DataGrid"
import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../../../../../../features/translations/TranslationKeys"
import { ReactNode } from "react"
import { getIdsOfEnabledDcDimensioningResults, getIdsOfBestDcDimensioningResults, mapDimensioningDcColumns } from "../AbbDimensioningDc.utils"

interface AbbDimensioningDcsDataGridProps {
    configurationId: string
    parameterId: string
    renderProductNameCell: (params: GridRenderCellParams<DcsDimensioningResultEntry>) => ReactNode
    dataGridProps?: Partial<DataGridProProps>
}

export function AbbDimensioningDcsDataGrid({ configurationId, parameterId, renderProductNameCell, dataGridProps }: Readonly<AbbDimensioningDcsDataGridProps>) {
    const dimensioningDcsQuery = dimensioningApi.useDimensioningDcsQuery({ configurationId, parameterId })
    const characteristics = [
        CatalogApi.useCharacteristicQuery(Characteristics.DcModuleFrameSize.id).data,
        CatalogApi.useCharacteristicQuery(Characteristics.DcModuleDimension.id).data,
        CatalogApi.useCharacteristicQuery(Characteristics.DcThyristorMaxTemp.id).data,
        CatalogApi.useCharacteristicQuery(Characteristics.DcProductName.id).data,
        CatalogApi.useCharacteristicQuery(Characteristics.DcThyristorTemp.id).data,
        CatalogApi.useCharacteristicQuery(Characteristics.DcCurrent.id).data,
        CatalogApi.useCharacteristicQuery(Characteristics.DcMargin.id).data
    ]

    const highlighted = getIdsOfBestDcDimensioningResults(dimensioningDcsQuery.data)
    const enabled = getIdsOfEnabledDcDimensioningResults(dimensioningDcsQuery.data)

    const getRowClassName = (params: GridRowClassNameParams<DcsDimensioningResultEntry>) => (highlighted.includes(params.row.id) ? "highlighted" : "")

    return (
        <>
            <Button variant="contained" size="large" onClick={dimensioningDcsQuery.refetch}>
                {L10n.format(TranslationKeys.pages.configuration.dimensioningButtonLabel)}
            </Button>
            <DataGrid
                {...dataGridProps}
                columns={mapDimensioningDcColumns(AbbDimensioningDcsColumns, characteristics, renderProductNameCell)}
                rows={dimensioningDcsQuery.data?.filter(r => enabled.includes(r.id)) ?? []}
                getRowClassName={getRowClassName}
                loading={dimensioningDcsQuery.isFetching}
            />
        </>
    )
}
