import { DragHandle } from "@mui/icons-material"
import { GridRenderCellParams } from "@mui/x-data-grid-pro"
import SalesUtils from "../../../../../../../features/sales/sales.utils"
import { BomNode } from "../../LineItemsDataGrid"
import { useAppSelector } from "../../../../../../../store/store"

export const DragAndDropButtonCell = (params: GridRenderCellParams<BomNode>) => {
    const readOnly = useAppSelector(state => state.sales.salesDocumentReadOnly)
    return SalesUtils.lineItems.isDraggableLineItem(params.row.lineItem) && !readOnly ? (
        <DragHandle style={{ cursor: "grab" }} className="DragAndDropButton" />
    ) : null
}
