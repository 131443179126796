import { useCallback } from "react"
import { VisualizationApi } from "../../../features/visualization/visualization.api"
import useCuiAppSettings from "./useCuiAppSettings"

export function useLazyRenderingsQuery() {
    const [loadRenderings, result] = VisualizationApi.useLazyRenderingsQuery()
    const cuiAppSettings = useCuiAppSettings()
    const fetch = useCallback(() => loadRenderings(cuiAppSettings!), [loadRenderings, cuiAppSettings])
    return { fetch, result }
}
