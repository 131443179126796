export const StringSalesDocumentProperties = {
    ADDITIONAL_INFORMATION: "additional_information",
    AREA_SALES_MANAGER_EMAIL: "area_sales_manager_email",
    AREA_SALES_MANAGER_NAME: "area_sales_manager_name",
    CREATED_BY: "quote_created_by",
    CUSTOMER: "customer",
    DESTINATION_COUNTRY: "DESTINATION_COUNTRY",
    DRIVESMART_ID: "drivesmart_id",
    END_CUSTOMER: "end_customer",
    FACTORY_OFFER_REF: "factory_offer_ref",
    FACTORY_SAP_ORDER_NUMBER: "factory_sap_order_number",
    INDUSTRY_SEGMENT: "INDUSTRY_SEGMENT",
    LOCAL_SALES_UNIT: "LOCAL_SALES_UNIT",
    MODIFIED_BY: "quote_modified_by",
    QUOTE_CURRENCY: "quote_currency",
    PRICES_CURRENCY: "prices_currency",
    QUOTE_ID: "quote_id",
    QUOTE_LOCKED_BY: "quote_locked_by",
    QUOTE_STATUS: "quote_status",
    PRICE_LIST: "quote_pricelist",
    PRODUCT_CATEGORY: "PRODUCT_CATEGORY",
    PROJECT_NAME: "project_name",
    SALESFORCE_OPPORTUNITY_NUMBER: "salesforce_opportunity_number",
    SALES_UNIT_CONTACT_EMAIL: "sales_unit_contact_email",
    SALES_UNIT_CONTACT_NAME: "sales_unit_contact_name"
} as const

export const DateSalesDocumentProperties = {
    CREATED_AT: "quote_created_at",
    MODIFIED_AT: "quote_modified_at"
} as const

export const NumberSalesDocumentProperties = {
    CUSTOMER_NUMBER: "customer_number"
} as const

export const SalesDocumentProperties = {
    ...StringSalesDocumentProperties,
    ...DateSalesDocumentProperties,
    ...NumberSalesDocumentProperties
} as const

export const SalesDocumentStatusValues = {
    Closed: "closed",
    Draft: "draft",
    Offer: "offer",
    Order: "order"
} as const

export const SalesDocumentsPaging = {
    INITIAL_PAGE: 0,
    INITIAL_PAGE_SIZE: 10,
    DEFAULT_SORT_FIELD: SalesDocumentProperties.MODIFIED_AT,
    DEFAULT_SORT_DIRECTION: "desc"
} as const

export const StringLineItemProperties = {
    ABB_LINE_ITEM_ID: "ABB_LINE_ITEM_ID",
    ARTICLE_ID: "ARTICLE_ID",
    CONFIGURATION_NAME: "CONFIGURATION_NAME",
    CREATED_AT: "CREATED_AT",
    CREATED_BY: "CREATED_BY",
    MODIFIED_AT: "MODIFIED_AT",
    MODIFIED_BY: "MODIFIED_BY",
    PLUS_CODE: "PLUSCODE",
    POSITION_NUMBER: "positionnumber",
    PRICEGROUP: "PRICEGROUP",
    PRODUCT_CATEGORY: "PRODUCT_CATEGORY",
    PRODUCT_NAME: "label",
    READY_STATE: "readystate",
    SHORT_TEXT: "shortText",
    TYPE_CODE: "TYPE_CODE",
    QUANTITY_CHANGED_MANUALLY: "QUANTITY_CHANGED_MANUALLY",
    IS_COMMON_FOLDER: "IS_COMMON_FOLDER",
    KNOWLEDGEBASE_VERSION: "KNOWLEDGEBASE_VERSION"
} as const

export const NumberLineItemProperties = {
    MULTIPLIER: "multiplier",
    PLAIN_LIST_PRICE: "plainListPrice",
    PLAIN_LIST_PRICE_WITH_CURRENCY: "plainListPriceWithCurrency",
    PLAIN_TRANSFER_PRICE: "plainTransferPrice",
    POSITION_QUANTITY: "newAmount",
    TRANSFER_PRICE: "transferPrice",
    TOTAL_LIST_PRICE: "totalListPrice",
    TOTAL_TRANSFER_PRICE: "totalTransferPrice",
    CALCULATED_TRANSFER_PRICE: "calculatedTransferPrice",
    CALCULATED_TOTAL_TRANSFER_PRICE: "calculatedTotalTransferPrice",
    NEW_BASIC_PLAIN_LIST_PRICE: "newBasicPlainListPrice",
    TRANSFER_PRICE_DISCOUNT_PERCENTAGE: "transferPriceDiscountPercentage",
    TRANSFER_PRICE_DISCOUNTED_UNIT_PRICE: "transferPriceDiscountedUnitPrice",
    TRANSFER_PRICE_DISCOUNTED_TOTAL_PRICE: "transferPriceDiscountedTotalPrice",
    TRANSFER_PRICE_DISCOUNT_ACCUMULATED_PERCENTAGE: "transferPriceDiscountAccumulatedPercentage"
} as const

export const BooleanLineItemProperties = {
    QUOTE_RELEVANT: "quote_relevant"
} as const

export const LineItemProperties = {
    ...StringLineItemProperties,
    ...NumberLineItemProperties,
    ...BooleanLineItemProperties
} as const

export const SalesRoles = {
    QA_BASE: "ROLE_QA_BASE"
} as const
