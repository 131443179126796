import { EnvironmentApi } from "../../environment/environment.api"
import SalesApi from "../../sales/sales.api"
import { useAppSelector } from "../../../store/store"

export const useLogout = () => {
    const environment = EnvironmentApi.usePublicEnvironmentQuery().data!
    const [triggerClose] = SalesApi.useCloseMutation()
    const idToken = useAppSelector(state => state.oidc.idToken)
    return () => {
        triggerClose().catch(error => console.warn("failed to end session", error))
        const endSessionUri = environment.endSessionUri
        if (!idToken) {
            console.error("missing id token value")
        } else if (!endSessionUri) {
            console.error("missing end session uri")
        } else {
            const redirectUri = window.location.origin
            window.location.href = endSessionUri + "?id_token_hint=" + idToken + "&post_logout_redirect_uri=" + redirectUri
        }
    }
}
