import { Grid, GridProps } from "@mui/material"
import Dropdown, { DropdownMenuItem } from "../../dropdown/Dropdown"
import { DatePicker } from "@mui/x-date-pickers"
import dayjs from "dayjs"
import CheckBoxGroup, { CheckBoxGroupOption } from "../../checkBoxGroup/CheckBoxGroup"
import { FocusEvent, HTMLInputTypeAttribute } from "react"
import parseJSON from "../../../utils/parseJSON"
import InfoIcon from "../../icons/infoIcon/InfoIcon"
import GenericFormInput from "./genericFormInput/GenericFormInput"
import { L10n } from "@encoway/l10n"

export enum InputType {
    Dropdown,
    CheckboxGroup
}

export interface GenericInput {
    type?: InputType | HTMLInputTypeAttribute
    id: string
    label: string
    informationText?: string
    values?: DropdownMenuItem[] | CheckBoxGroupOption[]
    value?: string
    mandatory?: boolean
    maxLength?: number
    disabled?: boolean
}

export interface GenericFormProps extends GridProps {
    validateInput: (input: GenericInput) => boolean
    values: Record<string, any>
    update: (inputId: string, value: string | number) => void
    inputs: GenericInput[]
    disableInput?: (id: GenericInput["id"]) => boolean
    onFocusInput?: (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void
}

export default function GenericForm({ validateInput, values, update, inputs, disableInput, onFocusInput, ...props }: Readonly<GenericFormProps>) {
    const disable = (input: GenericInput) => input.disabled || disableInput?.(input.id)
    return (
        <Grid container {...props}>
            {inputs.map(input => {
                switch (input.type) {
                    case InputType.Dropdown:
                        return (
                            <Grid item key={input.id} xs={1}>
                                <Dropdown
                                    cypressId={input.id}
                                    value={values[input.id] as string}
                                    label={L10n.format(input.label)}
                                    menuItems={(input.values as DropdownMenuItem[] | undefined) ?? []}
                                    required={input.mandatory}
                                    disabled={disable(input)}
                                    error={!validateInput(input)}
                                    infoText={input.informationText}
                                    onChange={event => update(input.id, event.target.value)}
                                    fullWidth
                                />
                            </Grid>
                        )
                    case InputType.CheckboxGroup:
                        return (
                            <Grid item key={input.id} xs={1}>
                                <CheckBoxGroup
                                    label={L10n.format(input.label)}
                                    options={input.values as CheckBoxGroupOption[]}
                                    values={parseJSON(values[input.id] as string)}
                                    required={input.mandatory}
                                    disabled={disable(input)}
                                    infoText={input.informationText}
                                    onChange={value => update(input.id, JSON.stringify(value))}
                                />
                            </Grid>
                        )
                    case "date":
                        return (
                            <Grid item key={input.id} xs={1}>
                                <DatePicker
                                    value={values[input.id] ? dayjs(values[input.id] as string) : undefined}
                                    onChange={value => update(input.id, value?.toDate().toString() ?? "")}
                                    disabled={disable(input)}
                                    slotProps={{ textField: { required: input.mandatory } }}
                                    label={
                                        <>
                                            <span>{L10n.format(input.label)}</span>
                                            {input.informationText ? <InfoIcon info={input.informationText} /> : null}
                                        </>
                                    }
                                />
                            </Grid>
                        )
                    default:
                        return (
                            <Grid item key={input.id} xs={1}>
                                <GenericFormInput
                                    input={input}
                                    value={input.value ?? values[input.id] ?? ""}
                                    disabled={disable(input)}
                                    error={!validateInput(input)}
                                    onBlur={update}
                                    onFocus={onFocusInput}
                                />
                            </Grid>
                        )
                }
            })}
        </Grid>
    )
}
