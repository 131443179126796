import { useCallback } from "react"
import { VisualizationApi } from "../../../features/visualization/visualization.api"
import useCuiAppSettings from "./useCuiAppSettings"
import useParams from "../../../router/hooks/useParams"
import useConfigurationProduct from "../../../features/sales/hooks/useConfigurationProduct"

export function useLazyArQuery() {
    const [loadAr, result] = VisualizationApi.useLazyArQuery()
    const cuiAppSettings = useCuiAppSettings()
    const params = useParams()
    const product = useConfigurationProduct()

    const fetch = useCallback(
        () => loadAr({ product: product!, id: params.lineItemId, cuiAppSettings: cuiAppSettings! }),
        [cuiAppSettings, loadAr, params.lineItemId, product]
    )

    return { fetch, result }
}
