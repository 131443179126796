import { useCallback, useEffect } from "react"
import { generatePath, Outlet } from "react-router-dom"
import { L10n } from "@encoway/l10n"
import { Badge, Box, Grid, Stack, Typography } from "@mui/material"
import { CheckCircle } from "@mui/icons-material"
import TranslationKeys from "../../features/translations/TranslationKeys"
import useParams from "../../router/hooks/useParams"
import BackToLink from "../../components/links/backToLink/BackToLink"
import theme from "../../App.theme"
import Tabs from "../../components/tabs/Tabs"
import SaveButton from "../../components/buttons/saveButton/SaveButton"
import MenuButton from "../../components/buttons/menuButton/MenuButton"
import useProjectForms from "./hooks/useProjectForms/useProjectForms"
import ProjectPageStyles from "./ProjectPage.styles"
import Icon from "../../components/icons/Icon"
import { useAppSelector } from "../../store/store"
import { ShouldBlock } from "../../router/hooks/useBlocker"
import RouterPaths from "../../router/constants/RouterPaths"
import SalesApi from "../../features/sales/sales.api"
import { ProductGroupIds } from "../../features/catalog/catalog.constants"
import { SalesDocumentStatusValues } from "../../features/sales/sales.constants"
import SalesUtils from "../../features/sales/sales.utils"
import { MainContainer } from "../../components/mainContainer/MainContainer"
import { GenericFormUtils } from "../../components/forms/genericForm/GenericForm.utils"

export default function ProjectPage() {
    const salesDocumentIsSaved = useAppSelector(state => state.sales.salesDocumentIsSaved)
    const readOnly = useAppSelector(state => state.sales.salesDocumentReadOnly)

    const [openSalesDocument, openSalesDocumentResult] = SalesApi.useOpenSalesDocumentMutation()
    const salesDocument = SalesApi.useSalesDocumentQuery(undefined, { skip: !openSalesDocumentResult.isSuccess }).data
    const [closeSalesDocument] = SalesApi.useCloseSalesDocumentMutation()
    const [saveSalesDocument] = SalesApi.useSaveSalesDocumentMutation()
    const [setSalesDocumentStatus] = SalesApi.useSetSalesDocumentStatusMutation()

    const params = useParams()
    const forms = useProjectForms()

    useEffect(() => {
        openSalesDocument(params.salesDocumentId!)
        return () => {
            closeSalesDocument()
        }
    }, [openSalesDocument, closeSalesDocument, params.salesDocumentId])

    const shouldBlockNavigation: ShouldBlock = useCallback(
        args => {
            return salesDocument ? !salesDocumentIsSaved && !args.nextLocation.pathname.includes(salesDocument.salesDocumentId) : false
        },
        [salesDocument, salesDocumentIsSaved]
    )

    if (!salesDocument) return null

    const numberOfInvalidInputs = forms.reduce(
        (result, form) => result + GenericFormUtils.getNumberOfInvalidInputs(form.formProps.inputs, salesDocument.properties),
        0
    )

    const tabs = [
        {
            cypressId: "tab.details",
            label: L10n.format(TranslationKeys.pages.project.tabs.details),
            route: generatePath(RouterPaths.PROJECT_DETAILS, { salesDocumentId: salesDocument.salesDocumentId }),
            icon:
                numberOfInvalidInputs > 0 ? (
                    <Badge badgeContent={numberOfInvalidInputs} color="error" sx={ProjectPageStyles.badge} />
                ) : (
                    <Icon component={CheckCircle} color="success" />
                )
        },
        {
            cypressId: "tab.catalog",
            label: L10n.format(TranslationKeys.pages.project.tabs.catalog),
            route: generatePath(RouterPaths.PROJECT_CATALOG, { salesDocumentId: salesDocument.salesDocumentId, productGroupIds: ProductGroupIds.Catalog })
        },
        {
            cypressId: "tab.composition",
            label: L10n.format(TranslationKeys.pages.project.tabs.composition),
            route: generatePath(RouterPaths.PROJECT_COMPOSITION, { salesDocumentId: salesDocument.salesDocumentId })
        },
        {
            cypressId: "tab.reports",
            label: L10n.format(TranslationKeys.pages.project.tabs.reports),
            route: generatePath(RouterPaths.PROJECT_REPORTS, { salesDocumentId: salesDocument.salesDocumentId })
        }
    ]

    const statusValues = Object.values(SalesDocumentStatusValues).map(value => ({
        value,
        text: SalesUtils.salesDocument.getStatusTranslation(value)!,
        cypressId: value
    }))

    return params.lineItemId || params.productId ? (
        <Outlet />
    ) : (
        <MainContainer>
            <Stack spacing={5} sx={ProjectPageStyles.container}>
                <BackToLink text={L10n.format(TranslationKeys.pages.projects.pageName)} route={RouterPaths.PROJECTS} />

                <Stack direction="row" justifyContent="space-between">
                    <Stack spacing={1}>
                        <Stack direction="row" alignItems="center" spacing={2} sx={ProjectPageStyles.projectNameContainer}>
                            <Typography data-cy={`Typography.ProjectName`} variant="h2" sx={ProjectPageStyles.projectName}>
                                {salesDocument.properties.project_name}
                            </Typography>
                            {readOnly && <Typography color={theme.palette.warning.main}>{L10n.format(TranslationKeys.common.readOnly)}</Typography>}
                        </Stack>
                        <Typography data-cy={`Typography.ProjectId`} fontWeight={theme.typography.fontWeightBold}>
                            {salesDocument.properties.quote_id}
                        </Typography>
                    </Stack>
                    <Stack>
                        <Stack direction="row" spacing={2}>
                            <MenuButton
                                variant="contained"
                                text={L10n.format(TranslationKeys.pages.project.changeStatusButtonLabel)}
                                disabled={readOnly}
                                color="secondary"
                                onSelectItem={setSalesDocumentStatus}
                                value={salesDocument.properties.quote_status}
                                items={statusValues}
                            />
                            <SaveButton
                                label={L10n.format(TranslationKeys.pages.project.saveButtonLabel)}
                                disabled={salesDocumentIsSaved || readOnly}
                                shouldBlockNavigation={shouldBlockNavigation}
                                shouldBlockUnload={!salesDocumentIsSaved}
                                onClick={() => saveSalesDocument()}
                            />
                        </Stack>
                    </Stack>
                </Stack>

                <Box>
                    <Grid container columnSpacing={10} rowSpacing={1}>
                        {SalesUtils.salesDocument.getMainProperties(salesDocument).map(property => (
                            <Grid item key={property.name}>
                                <Typography fontWeight={theme.typography.fontWeightBold}>{property.name}</Typography>
                                <Typography>{property.value as string}</Typography>
                            </Grid>
                        ))}
                    </Grid>
                </Box>

                <Tabs tabs={tabs} tabsStyles={ProjectPageStyles.tabStyles}>
                    <Outlet />
                </Tabs>
            </Stack>
        </MainContainer>
    )
}
