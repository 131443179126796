export const OidcRoles = {
    EDIT_PROJECTS_ROLE: "DriveHub_Projects_Edit",
    USER_INFO_LSU_ROLE_PREFIX: "DriveHub_UserInfo_Group_LSU_",
    UPDATE_PROJECT_LSU_ROLE: "DriveHub_Projects_LSU_Update",
    READ_TRANSFER_PRICES_ROLE: "DriveHub_TransferPrices_Read",
    READ_MULTIPLIER_ROLE: "DriveHub_Multiplier_Read",
    READ_LIST_PRICES_ROLE: "DriveHub_ListPrices_Read",
    READ_TRANSFERPRICES_DISCOUNT_ROLE: "DriveHub_TransferPrices_Discount_Read",
    UPDATE_TRANSFERPRICES_DISCOUNT_ROLE: "DriveHub_TransferPrices_Discount_Update"
} as const
