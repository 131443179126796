import { Info } from "@mui/icons-material"
import Icon from "../.././icons/Icon"
import { TooltipProps } from "@mui/material"

interface InfoIconProps {
    info: TooltipProps["title"]
}

export default function InfoIcon({ info }: InfoIconProps) {
    return (
        <Icon
            component={Info}
            fontSize="small"
            tooltipProps={{
                title: info
            }}
        />
    )
}
