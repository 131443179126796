import { DragAndDropHoverPosition } from "./Row"
import theme from "../../../../../../../App.theme"
import { XYCoord } from "react-dnd"
import { CSSProperties } from "react"

export const RowStyles = (hover: DragAndDropHoverPosition, isDragging?: boolean) => ({
    borderTopWidth: "2px",
    borderTopColor: hover === "top" ? theme.palette.secondary.light : "transparent",
    borderBottomWidth: "2px",
    borderBottomColor: hover === "bottom" ? theme.palette.secondary.light : "transparent",
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderStyle: "solid",
    backgroundColor: hover === "center" ? theme.palette.secondary.light : "transparent",
    boxSizing: "border-box",
    visibility: isDragging ? "hidden" : undefined
})

export const RowPreviewStyles = (index: number, element?: HTMLDivElement | null, difference?: XYCoord | null) => ({
    ...RowStyles(undefined),
    backgroundColor: theme.palette.background.paper,
    opacity: 0.8,
    position: "absolute",
    top: element && difference ? element.getBoundingClientRect().height * index + difference.y : 0,
    zIndex: theme.zIndex.tooltip,
    pointerEvents: "none"
})

export const OverlayStyles = (index: number, element?: HTMLDivElement | null): CSSProperties => ({
    position: "absolute",
    top: element ? element.getBoundingClientRect().height * index : 0,
    left: 0,
    zIndex: theme.zIndex.tooltip - 1,
    cursor: "not-allowed",
    width: element ? element.getBoundingClientRect().width : 0,
    height: element ? element.getBoundingClientRect().height : "40px"
})
