import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react"
import { Characteristic, PriceList, UpdateServerResponse } from "@encoway/sales-api-js-client"
import { getAllExpandedLineItems, getLineItems, getLineItemsToExpand, salesQueryFn } from "./sales.api.utils"
import { L10n } from "@encoway/l10n"
import TranslationKeys from "../translations/TranslationKeys"
import { AbbLineItem, AbbLineItemProperties, AbbSalesDocumentEntity, AbbSalesDocumentProperties } from "./sales.types"
import { LineItemProperties } from "./sales.constants"
import {
    AddLineItemsArgs,
    Composition,
    DuplicateLineItemArgs,
    GetSalesDocumentsArgs,
    GetSalesDocumentsResponse,
    MoveLineItemsArgs,
    PrintArgs,
    PrintResponse,
    UpdateLineItemArgs,
    UpdateSalesDocumentArgs
} from "./sales.api.types"
import { addItemsQuery } from "././queries/addItemsQuery"
import { ConfigurationApi, ConfigurationApiTags } from "../configuration/configuration.api"

export const SalesApiTags = {
    SALES_DOCUMENTS: "salesDocuments",
    SALES_DOCUMENT: "salesDocument",
    COMPOSITION: "composition",
    LINE_ITEMS: "lineItems",
    LINE_ITEM: "lineItem"
} as const

const SalesApi = createApi({
    reducerPath: "salesApi",
    tagTypes: Object.values(SalesApiTags),
    baseQuery: fakeBaseQuery<Error>(),
    endpoints: builder => ({
        // Queries:
        salesDocuments: builder.query<GetSalesDocumentsResponse, GetSalesDocumentsArgs>({
            providesTags: [SalesApiTags.SALES_DOCUMENTS],
            queryFn: salesQueryFn({
                query: (arg, service) => service.custom.call("/search/projects", arg)
            })
        }),

        salesDocument: builder.query<AbbSalesDocumentEntity, void>({
            providesTags: [SalesApiTags.SALES_DOCUMENT],
            queryFn: salesQueryFn({
                query: (_arg, service) => service.salesDocument.get()
            })
        }),

        composition: builder.query<Composition, void>({
            providesTags: [SalesApiTags.COMPOSITION],
            queryFn: salesQueryFn({
                query: async (_arg, _service, api) => {
                    const lineItemIds = (api.getState() as any).sales.expandedLineItems
                    const ids = ([undefined] as (string | undefined)[]).concat(lineItemIds)
                    const promiseResult: PromiseSettledResult<AbbLineItem[]>[] = await Promise.allSettled(ids.map(id => getLineItems(api, id)))
                    return promiseResult.reduce((result: Record<string, AbbLineItem[]>, entry, index) => {
                        if (entry.status === "fulfilled") result[ids[index] ?? "ROOT"] = entry.value
                        return result
                    }, {})
                }
            })
        }),

        lineItems: builder.query<AbbLineItem[], string | void>({
            providesTags: [SalesApiTags.LINE_ITEMS],
            queryFn: salesQueryFn({
                query: (lineItemId, service) => service.lineItems.get(lineItemId ?? undefined)
            })
        }),

        lineItem: builder.query<AbbLineItem, string>({
            providesTags: [SalesApiTags.LINE_ITEM],
            queryFn: salesQueryFn({
                query: (lineItemId, service) => service.custom.call("/lineItem/get", { lineItemId })
            })
        }),

        priceLists: builder.query<PriceList[], void>({
            queryFn: salesQueryFn({
                query: (_arg, service) => service.masterData.priceLists()
            })
        }),

        filterCharacteristics: builder.query<Characteristic[], string>({
            queryFn: salesQueryFn({
                query: (productGroupId, service) => service.masterData.filterCharacteristics(productGroupId)
            })
        }),

        // Mutations:

        createSalesDocument: builder.mutation<AbbSalesDocumentEntity, Partial<AbbSalesDocumentProperties>>({
            invalidatesTags: [SalesApiTags.SALES_DOCUMENTS],
            queryFn: salesQueryFn({
                query: async (properties, service) => {
                    await service.salesDocuments.create("quote")
                    await service.salesDocument.update(properties, {})
                    return service.salesDocument.save()
                }
            })
        }),

        openSalesDocument: builder.mutation<void, string>({
            invalidatesTags: [SalesApiTags.SALES_DOCUMENT, SalesApiTags.COMPOSITION, SalesApiTags.LINE_ITEMS, SalesApiTags.LINE_ITEM],
            queryFn: salesQueryFn({
                query: async (salesDocumentId, service) => {
                    await service.custom.call("/salesdocuments/getbyids", [salesDocumentId]) // necessary because sales document must be loaded before opening it
                    await service.salesDocuments.open(salesDocumentId, true)
                }
            })
        }),

        closeSalesDocument: builder.mutation<void, void>({
            invalidatesTags: [SalesApiTags.SALES_DOCUMENT],
            queryFn: salesQueryFn({
                query: (_arg, service) => service.salesDocuments.create("quote")
            })
        }),

        close: builder.mutation<string, void>({
            queryFn: salesQueryFn({
                query: (_arg, service) => service.close()
            })
        }),

        deleteSalesDocument: builder.mutation<void, string>({
            invalidatesTags: [SalesApiTags.SALES_DOCUMENTS],
            queryFn: salesQueryFn({
                query: (salesDocumentId, service) => service.salesDocuments.delete(salesDocumentId)
            })
        }),

        copySalesDocument: builder.mutation<void, string>({
            invalidatesTags: [SalesApiTags.SALES_DOCUMENTS],
            queryFn: salesQueryFn({
                query: async (salesDocumentId, service) => {
                    await service.salesDocuments.copy(salesDocumentId)
                    await service.salesDocument.save()
                }
            })
        }),

        updateSalesDocument: builder.mutation<AbbSalesDocumentEntity, UpdateSalesDocumentArgs>({
            invalidatesTags: [SalesApiTags.SALES_DOCUMENT, SalesApiTags.LINE_ITEMS, SalesApiTags.COMPOSITION],
            queryFn: salesQueryFn({
                query: (args, service) => service.salesDocument.update(...args)
            })
        }),

        saveSalesDocument: builder.mutation<AbbSalesDocumentEntity, void>({
            invalidatesTags: [SalesApiTags.SALES_DOCUMENTS],
            queryFn: salesQueryFn({
                query: (_arg, service) => service.salesDocument.save()
            })
        }),

        setSalesDocumentStatus: builder.mutation<void, string>({
            invalidatesTags: [SalesApiTags.SALES_DOCUMENTS, SalesApiTags.SALES_DOCUMENT],
            queryFn: salesQueryFn({
                query: async (status, service, api) => {
                    const state = api.getState() as any
                    const salesDocument = SalesApi.endpoints?.salesDocument.select()(state).data!
                    const currentStatus = salesDocument.properties.quote_status!
                    await service.process.execute(currentStatus, status)
                }
            })
        }),

        expandLineItem: builder.mutation<void, string>({
            invalidatesTags: [SalesApiTags.COMPOSITION],
            queryFn: () => ({ data: undefined })
        }),

        expandAllLineItems: builder.mutation<string[], void>({
            invalidatesTags: [SalesApiTags.COMPOSITION],
            queryFn: salesQueryFn({
                query: (_arg, _service, api) => getLineItemsToExpand(api)
            })
        }),

        collapseLineItem: builder.mutation<string[], string>({
            invalidatesTags: [SalesApiTags.COMPOSITION],
            queryFn: (lineItemId, api) => ({ data: getAllExpandedLineItems(api, lineItemId) })
        }),

        collapseAllLineItems: builder.mutation<void, void>({
            invalidatesTags: [SalesApiTags.COMPOSITION],
            queryFn: () => ({ data: undefined })
        }),

        addFolder: builder.mutation<UpdateServerResponse, void>({
            invalidatesTags: [SalesApiTags.LINE_ITEMS, SalesApiTags.COMPOSITION],
            queryFn: salesQueryFn({
                query: (_arg, service) => service.lineItems.addFolder(L10n.format(TranslationKeys.pages.project.composition.newFolderName), undefined, "FIRST")
            })
        }),

        addItems: builder.mutation<UpdateServerResponse, AddLineItemsArgs>({
            invalidatesTags: [SalesApiTags.SALES_DOCUMENT, SalesApiTags.LINE_ITEMS, SalesApiTags.COMPOSITION],
            queryFn: salesQueryFn({
                query: addItemsQuery,
                errorHandling: "snackbar",
                spinner: () => L10n.format(TranslationKeys.busy.product.addProducts)
            })
        }),

        addCustomLineItem: builder.mutation<UpdateServerResponse, Partial<AbbLineItemProperties>>({
            invalidatesTags: [SalesApiTags.SALES_DOCUMENT, SalesApiTags.LINE_ITEMS, SalesApiTags.COMPOSITION],
            queryFn: salesQueryFn({
                query: (properties, service) => service.customLineItems.addCustom({ name: "customLineItem", ...properties, isCustomLineItem: true })
            })
        }),

        updateLineItem: builder.mutation<UpdateServerResponse, UpdateLineItemArgs>({
            invalidatesTags: [SalesApiTags.SALES_DOCUMENT, SalesApiTags.LINE_ITEMS, SalesApiTags.COMPOSITION, SalesApiTags.LINE_ITEM],
            onQueryStarted: ([lineItemId, properties], api) => {
                api.dispatch(
                    SalesApi.util.updateQueryData("composition", undefined, data => {
                        Object.values(data).forEach(lineItems => {
                            const lineItem = lineItems.find(item => item.lineItemId === lineItemId)
                            if (lineItem) {
                                Object.entries(properties).forEach(([property, value]) => {
                                    lineItem.properties[property] = value
                                })
                            }
                        })
                    })
                )
            },
            queryFn: salesQueryFn({
                query: (args, service) => service.lineItems.update(...args),
                spinner: () => L10n.format(TranslationKeys.busy.lineItem.update)
            })
        }),

        moveLineItems: builder.mutation<UpdateServerResponse, MoveLineItemsArgs>({
            invalidatesTags: [SalesApiTags.SALES_DOCUMENT, SalesApiTags.LINE_ITEMS, SalesApiTags.COMPOSITION, SalesApiTags.LINE_ITEM],
            queryFn: salesQueryFn({
                query: (args, service) => service.lineItems.move(...args)
            })
        }),

        deleteLineItems: builder.mutation<UpdateServerResponse, void>({
            invalidatesTags: [SalesApiTags.SALES_DOCUMENT, SalesApiTags.LINE_ITEMS, SalesApiTags.COMPOSITION, SalesApiTags.LINE_ITEM],
            queryFn: salesQueryFn({
                query: async (_arg, service, api) => {
                    const state = api.getState() as any
                    const lineItemIds: string[] = state.sales.lineItemsToDelete
                    const childLineItemIds = lineItemIds.flatMap(lineItemId => getAllExpandedLineItems(api, lineItemId))
                    const response = await service.lineItems.delete(state.sales.lineItemsToDelete)
                    return { ...response, removeLineItemIds: response.removeLineItemIds.concat(childLineItemIds) }
                }
            })
        }),

        duplicateLineItem: builder.mutation<void, DuplicateLineItemArgs>({
            invalidatesTags: [SalesApiTags.SALES_DOCUMENT, SalesApiTags.LINE_ITEMS, SalesApiTags.COMPOSITION],
            queryFn: salesQueryFn({
                query: async (args, service) => {
                    const response = await service.lineItems.duplicate(...args)
                    Object.values(response.addedChildLineItems).forEach((lineItems: AbbLineItem[]) => {
                        lineItems.forEach(item => {
                            const configurationName = `${item.properties.CONFIGURATION_NAME} ${L10n.format(TranslationKeys.lineItem.copyAppendix)}`
                            service.lineItems.update(item.lineItemId, { [LineItemProperties.CONFIGURATION_NAME]: configurationName })
                        })
                    })
                }
            })
        }),

        createConfiguration: builder.mutation<string, string>({
            queryFn: salesQueryFn({
                query: (productId, service, api) => {
                    const result = service.configuration.create(productId)
                    api.dispatch(ConfigurationApi.util.invalidateTags([ConfigurationApiTags.CONFIGURATION_STATUS]))
                    return result
                }
            })
        }),

        openConfiguration: builder.mutation<string, string>({
            queryFn: salesQueryFn({
                query: (lineItemId, service, api) => {
                    const result = service.configuration.open(lineItemId)
                    api.dispatch(ConfigurationApi.util.invalidateTags([ConfigurationApiTags.CONFIGURATION_STATUS]))
                    return result
                }
            })
        }),

        viewConfiguration: builder.mutation<string, string>({
            queryFn: salesQueryFn({
                query: (lineItemId, service, api) => {
                    const result = service.configuration.open(lineItemId)
                    api.dispatch(ConfigurationApi.util.invalidateTags([ConfigurationApiTags.CONFIGURATION_STATUS]))
                    return result
                }
            })
        }),

        addConfiguration: builder.mutation<UpdateServerResponse, void>({
            invalidatesTags: [SalesApiTags.SALES_DOCUMENT, SalesApiTags.LINE_ITEMS, SalesApiTags.COMPOSITION, SalesApiTags.LINE_ITEM],
            queryFn: salesQueryFn({
                query: (_arg, service) => service.configuration.add(),
                spinner: () => L10n.format(TranslationKeys.busy.configuration.add)
            })
        }),

        saveConfiguration: builder.mutation<UpdateServerResponse, void>({
            invalidatesTags: [SalesApiTags.SALES_DOCUMENT, SalesApiTags.LINE_ITEMS, SalesApiTags.COMPOSITION, SalesApiTags.LINE_ITEM],
            queryFn: salesQueryFn({
                query: (_arg, service) => service.configuration.save(),
                spinner: () => L10n.format(TranslationKeys.busy.configuration.save)
            })
        }),

        stopConfiguration: builder.mutation<string, void>({
            queryFn: salesQueryFn({
                query: (_arg, service) => service.configuration.stop(),
                errorHandling: "none"
            })
        }),

        print: builder.mutation<PrintResponse, PrintArgs>({
            queryFn: salesQueryFn({
                query: (args, service) => service.custom.call("/print", args)
            })
        })
    })
})

export default SalesApi
