import theme from "../../../../App.theme"

export const ConfigurationHeaderStyles = {
    stack: {
        zIndex: theme.zIndex.appBar,
        backgroundColor: theme.palette.background.default
    },
    container: {
        display: "flex",
        flexDirection: "column",
        px: 2
    }
}
