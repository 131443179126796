import theme from "../../../../App.theme"
import { ICUIAppStyles } from "@encoway/cui-application-components"

export const CuiStyles: ICUIAppStyles = {
    root: {
        display: "none",
        ".configuration": {
            backgroundColor: theme.palette.background.default,
            order: 1
        }
    }
}
