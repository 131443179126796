import theme from "../../App.theme"

export const DataGridStyles = {
    borderColor: "transparent",
    ".MuiDataGrid-columnHeaders": {
        borderBottomColor: "black"
    },
    ".MuiDataGrid-columnSeparator": {
        color: theme.palette.divider
    },
    ".MuiDataGrid-columnHeaderTitle": {
        whiteSpace: "normal",
        lineHeight: "normal",
        textTransform: "uppercase"
    },
    ".MuiDataGrid-row.disabled": {
        color: theme.palette.text.disabled
    },
    "@media (hover: none) and (pointer: coarse)": {
        ".MuiDataGrid-columnHeader .MuiDataGrid-menuIcon": {
            width: "auto",
            visibility: "visible"
        }
    },
    "&.MuiDataGrid-root .MuiDataGrid-cell:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within, &.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within":
        {
            outline: "none"
        },
    "& .MuiDataGrid-row:hover": {
        cursor: "default"
    },
    "& .MuiDataGrid-cell--editable": {
        cursor: "pointer",
        "& .MuiInputBase-root": {
            height: "100%"
        }
    },
    "& .Mui-error": {
        backgroundColor: theme.palette.primary.light
    }
}
