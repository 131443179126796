import { useMemo } from "react"
import { projectInformationInputs } from "../constants/projectInformationInputs"
import { GenericInput } from "../../../../../components/forms/genericForm/GenericForm"
import { useLocalSalesUnitInput } from "../../../../../features/sales/hooks/useLocalSalesUnitInput"
import { usePriceListInput } from "../../../../../features/sales/hooks/usePriceListInput"
import { useSalesDocumentPropertyInput } from "../../../../../features/sales/hooks/useSalesDocumentPropertyInput"
import { SalesDocumentProperties } from "../../../../../features/sales/sales.constants"
import { Characteristics } from "../../../../../features/catalog/catalog.constants"

export function useProjectInformationFormInputs(): GenericInput[] {
    const localSalesUnitInput = useLocalSalesUnitInput()
    const priceListInput = usePriceListInput()
    const currencyInput = useSalesDocumentPropertyInput(SalesDocumentProperties.QUOTE_CURRENCY, Characteristics.ProjectCurrency.id, true)
    return useMemo(
        () => projectInformationInputs.concat([localSalesUnitInput, priceListInput, currencyInput]),
        [localSalesUnitInput, priceListInput, currencyInput]
    )
}
