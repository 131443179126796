import { SalesDocumentProperties } from "../sales.constants"
import TranslationKeys from "../../translations/TranslationKeys"

export const SalesDocumentPropertyInputDefinitions = {
    QUOTE_ID: {
        id: SalesDocumentProperties.QUOTE_ID,
        label: TranslationKeys.project.properties.id
    },
    CREATED_BY: {
        id: SalesDocumentProperties.CREATED_BY,
        label: TranslationKeys.project.properties.createdBy
    },
    CREATED_AT: {
        type: "date",
        id: SalesDocumentProperties.CREATED_AT,
        label: TranslationKeys.project.properties.createdAt
    },
    MODIFIED_AT: {
        type: "date",
        id: SalesDocumentProperties.MODIFIED_AT,
        label: TranslationKeys.project.properties.modifiedAt
    },
    MODIFIED_BY: {
        id: SalesDocumentProperties.MODIFIED_BY,
        label: TranslationKeys.project.properties.modifiedBy
    },
    ADDITIONAL_INFORMATION: {
        id: SalesDocumentProperties.ADDITIONAL_INFORMATION,
        label: TranslationKeys.project.properties.additionalInformation
    },
    PROJECT_NAME: {
        id: SalesDocumentProperties.PROJECT_NAME,
        label: TranslationKeys.project.properties.projectName,
        mandatory: true,
        maxLength: 50
    },
    FACTORY_OFFER_REF: {
        id: SalesDocumentProperties.FACTORY_OFFER_REF,
        label: TranslationKeys.project.properties.factoryOfferRef
    },
    FACTORY_SAP_ORDER_NUMBER: {
        id: SalesDocumentProperties.FACTORY_SAP_ORDER_NUMBER,
        label: TranslationKeys.project.properties.factorySapOrderNumber
    },
    CUSTOMER_NUMBER: {
        type: "number",
        id: SalesDocumentProperties.CUSTOMER_NUMBER,
        label: TranslationKeys.project.properties.customerNumber
    },
    SALESFORCE_OPPORTUNITY_NUMBER: {
        id: SalesDocumentProperties.SALESFORCE_OPPORTUNITY_NUMBER,
        label: TranslationKeys.project.properties.salesforceOpportunityNumber,
        maxLength: 20
    },
    CUSTOMER: {
        id: SalesDocumentProperties.CUSTOMER,
        label: TranslationKeys.project.properties.customer
    },
    END_CUSTOMER: {
        id: SalesDocumentProperties.END_CUSTOMER,
        label: TranslationKeys.project.properties.endCustomer
    },
    SALES_UNIT_CONTACT_NAME: {
        id: SalesDocumentProperties.SALES_UNIT_CONTACT_NAME,
        label: TranslationKeys.project.properties.salesUnitContactName,
        mandatory: true
    },
    SALES_UNIT_CONTACT_EMAIL: {
        type: "email",
        id: SalesDocumentProperties.SALES_UNIT_CONTACT_EMAIL,
        label: TranslationKeys.project.properties.salesUnitContactEmail,
        mandatory: true
    },
    AREA_SALES_MANAGER_NAME: {
        id: SalesDocumentProperties.AREA_SALES_MANAGER_NAME,
        label: TranslationKeys.project.properties.areaSalesManagerName,
        mandatory: true
    },
    AREA_SALES_MANAGER_EMAIL: {
        type: "email",
        id: SalesDocumentProperties.AREA_SALES_MANAGER_EMAIL,
        label: TranslationKeys.project.properties.areaSalesManagerEmail,
        mandatory: true
    }
}
