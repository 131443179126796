import { Box, Grid, Typography } from "@mui/material"
import { Product } from "@encoway/c-services-js-client"
import { L10n } from "@encoway/l10n"
import { ProductCard } from "../productCard/ProductCard"
import useNavigate from "../../../../../router/hooks/useNavigate"
import TranslationKeys from "../../../../../features/translations/TranslationKeys"
import CatalogUtils from "../../../../../features/catalog/catalog.utils"
import { useSearchParams } from "react-router-dom"
import { SEARCH_PARAM } from "../ProductSelection"
import SalesApi from "../../../../../features/sales/sales.api"
import { CatalogApi } from "../../../../../features/catalog/catalog.api"
import { Characteristics, ProductGroupIds } from "../../../../../features/catalog/catalog.constants"
import { useCallback } from "react"
import { getProductSelection } from "../ProductSelection.utils"

interface ProductCatalogProps {
    products: Product[]
    characteristicFilters: Record<string, any>
    isLoading?: boolean
}

export function ProductStandardSelection({ products, characteristicFilters, isLoading }: Readonly<ProductCatalogProps>) {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const searchValue = searchParams.get(SEARCH_PARAM) ?? undefined
    const salesDocument = SalesApi.endpoints.salesDocument.useQueryState().data!
    const [addItems] = SalesApi.useAddItemsMutation()
    const productCategory = salesDocument.properties.PRODUCT_CATEGORY
    const noProductsFoundWithSearch = !!searchValue && products.length === 0

    const extendedSearchProductSelection = getProductSelection(ProductGroupIds.Catalog, characteristicFilters, true, searchValue)
    const productsOfExtendedSearch = CatalogApi.useProductsQuery(extendedSearchProductSelection, { skip: !noProductsFoundWithSearch }).data?.products ?? []

    const addProduct = useCallback((product: Product) => addItems({ articlesToInsert: [{ articleId: product.id, quantity: 1 }] }), [addItems])

    const configureProduct = useCallback(
        (product: Product) => {
            navigate.toNewConfiguration(salesDocument.salesDocumentId, product.id)
        },
        [navigate, salesDocument.salesDocumentId]
    )

    return (
        <>
            {noProductsFoundWithSearch && (
                <Typography>
                    {L10n.format(
                        productsOfExtendedSearch.length > 0
                            ? TranslationKeys.pages.project.catalog.searchResultsOfCatalog
                            : TranslationKeys.pages.project.catalog.noSearchResultsMessage
                    )}
                </Typography>
            )}

            <Box>
                <Grid container spacing={4}>
                    {isLoading && (
                        <Grid item>
                            <ProductCard />
                        </Grid>
                    )}
                    {(noProductsFoundWithSearch ? productsOfExtendedSearch : products).map(product => {
                        const characteristicValue = CatalogUtils.getCharacteristicValue<string>(product, Characteristics.ProductCategory.id)
                        return (
                            <Grid item key={product.id}>
                                <ProductCard
                                    product={product}
                                    onConfigureClick={configureProduct}
                                    onAddToCompositionClick={addProduct}
                                    disabled={!!productCategory && !!characteristicValue && productCategory !== characteristicValue}
                                />
                            </Grid>
                        )
                    })}
                </Grid>
            </Box>
        </>
    )
}
