import { GridCell, GridCellProps, useGridApiContext } from "@mui/x-data-grid-pro"
import { MouseEvent, useRef } from "react"
import { CellStyles } from "./Cell.styles"
import EditIcon from "@mui/icons-material/Edit"
import { Tooltip } from "@mui/material"

export function Cell(props: GridCellProps) {
    const apiRef = useGridApiContext()
    const cellRef = useRef<HTMLDivElement>(null)

    const showEditIcon = props.isEditable && props.cellMode !== "edit" && props.column.type !== "boolean"

    const onClick = (event: MouseEvent<HTMLDivElement>) => {
        if (props.onClick) {
            props.onClick(event)
        } else if (showEditIcon) {
            apiRef.current.startCellEditMode({ id: props.rowId, field: props.field })
        }
    }

    // disable non-touch tooltip
    const children = props.column.renderCell ? props.children : props.children ?? props.formattedValue

    return (
        <Tooltip open={!!props.editCellState?.error} title={props.editCellState?.error ?? ""}>
            <>
                <Tooltip title={props.cellMode !== "edit" && !props.column.renderCell ? props.formattedValue ?? "" : ""}>
                    <GridCell {...props} onClick={onClick} onTouchStart={onClick} ref={cellRef} data-cy={`cell.${props.field}`}>
                        {children}
                    </GridCell>
                </Tooltip>
                {showEditIcon && (
                    <div style={CellStyles.editIconDiv}>
                        <EditIcon style={CellStyles.editIcon} />
                    </div>
                )}
            </>
        </Tooltip>
    )
}
