import { GridColumnHeaderParams, GridColumnHeaderTitle } from "@mui/x-data-grid-pro"
import { L10n } from "@encoway/l10n"
import SalesApi from "../../../../../../../features/sales/sales.api"

export default function HeaderWithCurrency(params: GridColumnHeaderParams, currencyProperty: string) {
    const salesDocument = SalesApi.endpoints.salesDocument.useQueryState().data!
    return (
        <GridColumnHeaderTitle
            label={`${L10n.format(params.colDef.headerName ?? params.field)} [${salesDocument.properties[currencyProperty]}]`}
            columnWidth={params.colDef.width ?? 100}
        />
    )
}
