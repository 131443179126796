import { Alert, Button } from "@mui/material"
import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../../translations/TranslationKeys"
import { ErrorAlertStyles } from "./ErrorAlert.styles"

interface ErrorAlertProps {
    error?: string
    onRetry?: () => any
}

export function ErrorAlert({ error, onRetry }: Readonly<ErrorAlertProps>) {
    return (
        <Alert severity="error" sx={ErrorAlertStyles}>
            {error ?? L10n.format(TranslationKeys.error.defaultErrorMessage)}
            {onRetry && (
                <Button variant="text" onClick={onRetry} disableRipple>
                    {L10n.format(TranslationKeys.error.retry)}
                </Button>
            )}
        </Alert>
    )
}
