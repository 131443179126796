import { useMemo } from "react"
import { GenericInput, InputType } from "../../../components/forms/genericForm/GenericForm"
import { CatalogApi } from "../../catalog/catalog.api"
import CatalogUtils from "../../catalog/catalog.utils"

export function useSalesDocumentPropertyInput(salesDocumentPropertyId: string, characteristicId: string, mandatory?: boolean, skip?: boolean): GenericInput {
    const characteristic = CatalogApi.useCharacteristicQuery(characteristicId, { skip }).data
    return useMemo(
        () => ({
            type: InputType.Dropdown,
            id: salesDocumentPropertyId,
            label: characteristic?.name ?? "",
            mandatory: mandatory,
            values: characteristic ? CatalogUtils.getPossibleValues(characteristic, true).map(pv => ({ value: pv.id, text: pv.value as string })) : []
        }),
        [characteristic, mandatory, salesDocumentPropertyId]
    )
}
