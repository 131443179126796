import { ReadyState } from "@encoway/cui-application-components"
import { ElementType } from "react"
import { CheckCircle, Error, QuestionMark } from "@mui/icons-material"
import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../translations/TranslationKeys"
import { AbbLineItem, AbbLineItemProperties } from "../sales.types"

export default class LineItemsUtils {
    static getStatusColor(status?: string) {
        switch (status) {
            case ReadyState.Ready:
                return "success"
            case ReadyState.NotReady:
                return "warning"
            case ReadyState.Conflict:
                return "error"
        }
    }

    static getStatusIcon(status?: string): ElementType {
        switch (status) {
            case ReadyState.Ready:
                return CheckCircle
            case ReadyState.NotReady:
                return Error
            case ReadyState.Conflict:
                return Error
            default:
                return QuestionMark
        }
    }

    static getStatusTranslation(status?: string) {
        switch (status) {
            case ReadyState.Ready:
                return L10n.format(TranslationKeys.lineItem.statusValues.ready)
            case ReadyState.NotReady:
                return L10n.format(TranslationKeys.lineItem.statusValues.notReady)
            case ReadyState.Conflict:
                return L10n.format(TranslationKeys.lineItem.statusValues.conflict)
        }
    }

    static isDraggableLineItem(lineItem: AbbLineItem) {
        return lineItem.permission.includes("UPDATE") && !lineItem.properties.isSubarticle
    }

    static isEditableLineItemProperty(lineItem: AbbLineItem, property: keyof AbbLineItemProperties) {
        return lineItem.permission.includes("UPDATE") && !!lineItem.propertyPermissions[property]?.includes("UPDATE")
    }

    static isFolder(lineItem: AbbLineItem) {
        return !lineItem.properties.isArticle
    }
}
