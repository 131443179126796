import { useRef, useState } from "react"
import { Box, Button, Grid } from "@mui/material"
import TuneIcon from "@mui/icons-material/Tune"
import { L10n } from "@encoway/l10n"
import { CharacteristicValueInfo } from "@encoway/c-services-js-client/src/catalog/types/response/characteristic/CharacteristicValueInfo"
import Dropdown from "../../../../../components/dropdown/Dropdown"
import TranslationKeys from "../../../../../features/translations/TranslationKeys"
import ProductFiltersStyles from "./ProductFilters.styles"
import mergeFilters from "./utils/mergeFilters"
import SalesApi from "../../../../../features/sales/sales.api"

interface ProductFiltersProps {
    productGroupId: string
    filterValues: Record<string, any>
    onUpdateFilter: (characteristicId: string, value: any) => void
    aggregatedCharacteristicValues: Record<string, CharacteristicValueInfo[]>
}

export default function ProductFilters({ productGroupId, filterValues, onUpdateFilter, aggregatedCharacteristicValues }: Readonly<ProductFiltersProps>) {
    const ref = useRef<HTMLDivElement>(null)
    const [showMore, setShowMore] = useState(false)
    const filterCharacteristics = SalesApi.useFilterCharacteristicsQuery(productGroupId).data

    const filters = mergeFilters(aggregatedCharacteristicValues, filterCharacteristics)
    const width = ref.current?.clientWidth ?? 0
    const maxFiltersInRow = Math.floor(width / ProductFiltersStyles.gridItem.minWidth)
    const showButton = maxFiltersInRow < (filters?.length ?? 0)

    return (
        <Box>
            <Grid container spacing={4} ref={ref}>
                {(!showMore && showButton ? filters.slice(0, maxFiltersInRow - 1) : filters).map(dropdown => (
                    <Grid item key={dropdown.characteristicId} sx={ProductFiltersStyles.gridItem}>
                        <Dropdown
                            unselect={true}
                            value={filterValues[dropdown.characteristicId] ?? ""}
                            label={dropdown.label ?? ""}
                            menuItems={dropdown.possibleValues.map(pv => ({ value: pv.value, text: pv.label! }))}
                            onChange={e => onUpdateFilter(dropdown.characteristicId, e.target?.value)}
                            cypressId={"ProductFilters"}
                            fullWidth
                        />
                    </Grid>
                ))}
                {showButton && (
                    <Grid item sx={ProductFiltersStyles.gridItem}>
                        <Button
                            variant="contained"
                            startIcon={<TuneIcon />}
                            sx={ProductFiltersStyles.button}
                            onClick={() => setShowMore(prevState => !prevState)}
                        >
                            {showMore
                                ? L10n.format(TranslationKeys.pages.project.catalog.showLess)
                                : L10n.format(TranslationKeys.pages.project.catalog.showMore)}
                        </Button>
                    </Grid>
                )}
            </Grid>
        </Box>
    )
}
