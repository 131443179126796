import { useMemo } from "react"
import { GenericInput } from "../../forms/genericForm/GenericForm"
import { SalesDocumentPropertyInputDefinitions } from "../../../features/sales/constants/SalesDocumentPropertyInputDefinitions"
import { useSalesDocumentPropertyInput } from "../../../features/sales/hooks/useSalesDocumentPropertyInput"
import { SalesDocumentProperties } from "../../../features/sales/sales.constants"
import { Characteristics } from "../../../features/catalog/catalog.constants"

export default function useNewProjectFormInputs(skip: boolean): GenericInput[] {
    const industrySegmentInput = useSalesDocumentPropertyInput(SalesDocumentProperties.INDUSTRY_SEGMENT, Characteristics.IndustrySegment.id, false, skip)
    const currencyInput = useSalesDocumentPropertyInput(SalesDocumentProperties.QUOTE_CURRENCY, Characteristics.ProjectCurrency.id, true, skip)
    return useMemo(
        () => [
            SalesDocumentPropertyInputDefinitions.PROJECT_NAME,
            industrySegmentInput,
            currencyInput,
            SalesDocumentPropertyInputDefinitions.SALESFORCE_OPPORTUNITY_NUMBER
        ],
        [currencyInput, industrySegmentInput]
    )
}
