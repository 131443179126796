import { useCallback, useEffect, useRef } from "react"
import { GridRow, GridRowProps, useGridApiContext } from "@mui/x-data-grid-pro"
import { DragSourceMonitor, DropTargetMonitor, useDrag, useDrop } from "react-dnd"
import { getDragAndDropHoverPosition, getHoverPosition, getParentFrom, getPreviousBomNode, isFooter } from "./rowUtils"
import SalesUtils from "../../../../../../../features/sales/sales.utils"
import SalesApi from "../../../../../../../features/sales/sales.api"
import { BomNode } from "../../LineItemsDataGrid"
import { OverlayStyles, RowPreviewStyles, RowStyles } from "./Row.styles"

const DRAG_AND_DROP_ITEM_TYPE = "lineItems"

export type DragAndDropHoverPosition = "top" | "center" | "bottom" | undefined

export default function Row(props: Readonly<GridRowProps>) {
    const apiRef = useGridApiContext()
    const ref = useRef<HTMLDivElement>(null)
    const bomNode = props.row as BomNode
    const salesDocument = SalesApi.endpoints.salesDocument.useQueryState().data!
    const [moveLineItems] = SalesApi.useMoveLineItemsMutation()

    const canDrag = useCallback(
        (monitor: DragSourceMonitor) => {
            const rect = ref.current?.getElementsByClassName("DragAndDropButton")[0]?.getBoundingClientRect()
            const clientOffset = monitor.getClientOffset()
            return !!(rect && clientOffset && clientOffset.x >= rect.x && clientOffset.x <= rect.x + rect.width)
        },
        [ref]
    )

    const canDrop = useCallback(
        (_draggedBomNode: BomNode, monitor: DropTargetMonitor) => {
            const hoverPosition = getHoverPosition(monitor, ref)
            if (hoverPosition === "top" || hoverPosition === "bottom") {
                return bomNode && !isFooter(bomNode.lineItem) && !!getParentFrom(bomNode, salesDocument)?.permission.includes("UPDATE")
            } else if (hoverPosition === "center") {
                return (
                    bomNode && !isFooter(bomNode.lineItem) && bomNode.lineItem.permission.includes("UPDATE") && SalesUtils.lineItems.isFolder(bomNode.lineItem)
                )
            }
            return false
        },
        [ref, salesDocument, bomNode]
    )

    const drop = useCallback(
        (draggedBomNode: BomNode, monitor: DropTargetMonitor) => {
            const hoverPosition = getHoverPosition(monitor, ref)
            if (hoverPosition === "top") {
                const bomNodes = apiRef.current.getSortedRows() as BomNode[]
                const previousBomNode = getPreviousBomNode(bomNode, bomNodes)
                moveLineItems([[draggedBomNode.lineItem.lineItemId], bomNode?.parent?.lineItem.lineItemId, previousBomNode?.lineItem.lineItemId])
            } else if (hoverPosition === "bottom") {
                moveLineItems([[draggedBomNode.lineItem.lineItemId], bomNode?.parent?.lineItem.lineItemId, bomNode.lineItem.lineItemId])
            } else if (hoverPosition === "center") {
                moveLineItems([[draggedBomNode.lineItem.lineItemId], bomNode.lineItem.lineItemId])
            }
        },
        [apiRef, moveLineItems, bomNode, ref]
    )

    const dropCollect = useCallback(
        (monitor: DropTargetMonitor) => {
            const rect = ref.current?.getBoundingClientRect()
            const clientOffset = monitor.getClientOffset()
            return {
                hover: rect && clientOffset && canDrop(monitor.getItem(), monitor) ? getDragAndDropHoverPosition(clientOffset, rect) : undefined,
                dropNotAllowed: !!monitor.getItem() && !canDrop(monitor.getItem(), monitor)
            }
        },
        [ref, canDrop]
    )

    const [dragProps, dragRef] = useDrag(
        () => ({
            type: DRAG_AND_DROP_ITEM_TYPE,
            item: bomNode,
            collect: monitor => ({ difference: monitor.getDifferenceFromInitialOffset(), isDragging: monitor.isDragging() }),
            canDrag: canDrag
        }),
        [bomNode, canDrag]
    )

    const [dropProps, dropRef] = useDrop(
        () => ({
            accept: DRAG_AND_DROP_ITEM_TYPE,
            collect: dropCollect,
            drop: drop,
            canDrop: canDrop
        }),
        [drop, dropCollect, canDrop]
    )

    useEffect(() => {
        dragRef(ref.current)
        dropRef(ref.current)
    }, [ref, dragRef, dropRef])

    return (
        <>
            <GridRow {...props} ref={ref} style={RowStyles(dropProps.hover, dragProps.isDragging)} />
            {dropProps.dropNotAllowed && <div style={OverlayStyles(props.index, ref.current)} />}
            {dragProps.isDragging && <GridRow {...props} style={RowPreviewStyles(props.index, ref.current, dragProps.difference)} />}
        </>
    )
}
