import Plot from "react-plotly.js"
import { Box } from "@mui/material"
import { L10n } from "@encoway/l10n"
import GraphStyles from "./Graph.styles"
import { useGraphQuery } from "../../../../../../features/graph/graph.api"
import TranslationKeys from "../../../../../../features/translations/TranslationKeys"
import AbbSpinner from "../AbbSpinner/AbbSpinner"

const Graph = (props: any) => {
    const graphQuery = useGraphQuery({
        parameterName: props.data.name,
        configurationId: props.options.appSettings.configuration.options.configurationId
    })

    const graphDefinition = structuredClone(graphQuery.data)

    return (
        <Box sx={GraphStyles.root}>
            {graphDefinition ? (
                <Plot data={graphDefinition.data} layout={{ title: props.data.translatedName, ...graphDefinition.layout }} config={{ displaylogo: false }} />
            ) : null}
            {graphQuery.error && L10n.format(TranslationKeys.pages.configuration.graph.renderError, { graphName: props.data.translatedName })}
            {graphQuery.isFetching && <AbbSpinner />}
        </Box>
    )
}

export default Graph
