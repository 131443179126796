import React, { useEffect, useState } from "react"
import { Http, useToken } from "@encoway/sales-showroom-auth"
import { ConfigurationService } from "@encoway/c-services-js-client"
import { Settings } from "../../settings"
import { useParams } from "react-router-dom"
import { ConAnApp } from "./components/ConAnApp/ConAnApp"
import AbbOverlaySpinner from "../configurationPage/components/cui/components/AbbSpinner/AbbOverlaySpinner"

export const ConanPage = () => {
    const [configurationService, setConfigurationService] = useState<ConfigurationService>()
    const params = useParams()
    const tokenFunction = useToken()
    const configurationId = params.configurationId
    useEffect(() => {
        if (configurationId) {
            const http = Http.Bearer(tokenFunction)
            ConfigurationService.create(http, Settings.configuration.baseUrl, { configurationId }, Settings.locale).then(setConfigurationService)
        }
    }, [configurationId, tokenFunction])
    return configurationService ? <ConAnApp configurationService={configurationService} /> : <AbbOverlaySpinner />
}
