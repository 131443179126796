import { RootProps } from "@encoway/cui-configurator-components"
import { ComponentFactory } from "@encoway/react-configurator"
import { ComponentName } from "@encoway/cui-configurator-components/src/constants"
import React, { useEffect } from "react"
import AbbRootStyles from "./AbbRoot.styles"
import { Portal } from "@mui/material"
import { ConfigurationPageContainerIds } from "../../../../ConfigurationPage.constants"

export default function AbbRoot(props: Readonly<RootProps>) {
    const configurationContainer = document.getElementById(ConfigurationPageContainerIds.CONFIGURATION)

    useEffect(() => {
        const mutationObserver = new MutationObserver(() => configurationContainer?.scrollTo({ top: 0, left: 0 }))
        mutationObserver.observe(configurationContainer!, { childList: true, subtree: false, attributes: false })
        return () => mutationObserver.disconnect()
    }, [configurationContainer])

    return (
        <Portal container={() => configurationContainer}>
            {props.data?.parameters?.length > 0 ? (
                <div style={AbbRootStyles}>{ComponentFactory.instanceOf(ComponentName.Section, props)}</div>
            ) : (
                ComponentFactory.instanceOf(ComponentName.Tabs, props)
            )}
        </Portal>
    )
}
