import { useCallback, useMemo, useState } from "react"
import { Button, Portal, Stack, Typography } from "@mui/material"
import { L10n } from "@encoway/l10n"
import { ProductGroup } from "@encoway/c-services-js-client"
import ProductQuickSelectionDataGrid from "./productQuickSelectionDataGrid/ProductQuickSelectionDataGrid"
import TranslationKeys from "../../../../../features/translations/TranslationKeys"
import CatalogUtils from "../../../../../features/catalog/catalog.utils"
import { Characteristics } from "../../../../../features/catalog/catalog.constants"
import Container from "@mui/material/Container"
import ExpandButton from "../../../../../components/buttons/expandButton/ExpandButton"
import { useAppSelector } from "../../../../../store/store"
import { isEmptySelection, selectionToArray, updateSelection } from "./ProductQuickSelection.utils"
import SalesApi from "../../../../../features/sales/sales.api"

interface ProductQuickSelectionProps {
    productGroups: ProductGroup[]
    characteristicFilters: Record<string, any>
}

export type ProductAmount = {
    [productId: string]: number
}

export type ProductSelection = {
    [group: string]: ProductAmount
}

export function ProductQuickSelection({ productGroups, characteristicFilters }: Readonly<ProductQuickSelectionProps>) {
    const [productSelection, setProductSelection] = useState<ProductSelection>({})
    const [collapsedProductGroups, setCollapsedProductGroups] = useState<string[]>([])
    const readOnly = useAppSelector(state => state.sales.salesDocumentReadOnly)
    const [addItems] = SalesApi.useAddItemsMutation()

    const updateProductQuantity = useCallback((productGroupId: string, productId: string, quantity: number) => {
        setProductSelection((prevState: ProductSelection) => updateSelection(prevState, productGroupId, productId, quantity))
    }, [])

    const addProducts = () => addItems({ articlesToInsert: selectionToArray(productSelection) }).then(() => setProductSelection({}))

    const toggleProductGroup = (productGroupId: string) => {
        setCollapsedProductGroups(prevState =>
            prevState.includes(productGroupId) ? prevState.filter(id => id !== productGroupId) : prevState.concat(productGroupId)
        )
    }

    const expandAllProductGroups = () => setCollapsedProductGroups([])
    const collapseAllProductGroups = () => setCollapsedProductGroups(productGroups.map(productGroup => productGroup.id))
    const disableAddProductsButton = useMemo(() => readOnly || isEmptySelection(productSelection), [readOnly, productSelection])

    return (
        <Stack spacing={5} marginTop={3}>
            <Stack direction="row" justifyContent="flex-end" spacing={2}>
                <Button variant="contained" color="secondary" onClick={expandAllProductGroups}>
                    {L10n.format(TranslationKeys.pages.project.catalog.quickSelection.expandAllProductGroups)}
                </Button>
                <Button variant="contained" color="secondary" onClick={collapseAllProductGroups}>
                    {L10n.format(TranslationKeys.pages.project.catalog.quickSelection.collapseAllProductGroups)}
                </Button>
            </Stack>
            <Stack spacing={5}>
                {productGroups.map(productGroup => (
                    <Stack key={productGroup.id} spacing={2}>
                        <Stack direction="row" alignItems="flex-start" spacing={1}>
                            <ExpandButton expanded={!collapsedProductGroups.includes(productGroup.id)} onToggle={() => toggleProductGroup(productGroup.id)} />
                            <Stack>
                                <Typography variant="h3">{productGroup.name}</Typography>
                                <Typography variant="inherit">{CatalogUtils.getCharacteristicValue(productGroup, Characteristics.ShortText.id)}</Typography>
                            </Stack>
                        </Stack>
                        {!collapsedProductGroups.includes(productGroup.id) && (
                            <ProductQuickSelectionDataGrid
                                productGroupId={productGroup.id}
                                characteristicFilters={characteristicFilters}
                                updateProductQuantity={updateProductQuantity}
                                selectedProducts={productSelection[productGroup.id]}
                            />
                        )}
                    </Stack>
                ))}
            </Stack>
            <Portal>
                <Container
                    maxWidth="xl"
                    sx={{ position: "fixed", bottom: 0, left: "50%", right: "50%", transform: "translate(-50%, -50%)", pointerEvents: "none" }}
                >
                    <Stack justifyContent="flex-end" direction="row" spacing={2} px={3}>
                        <Button
                            disabled={disableAddProductsButton}
                            variant="contained"
                            onClick={addProducts}
                            color="secondary"
                            sx={{ pointerEvents: "initial" }}
                        >
                            {L10n.format(TranslationKeys.pages.project.catalog.quickSelection.addToCompositionButtonLabel)}
                        </Button>
                    </Stack>
                </Container>
            </Portal>
        </Stack>
    )
}
