import { GridColDef } from "@mui/x-data-grid-pro"
import { Characteristics } from "../../../../../../../features/catalog/catalog.constants"

export const AbbDimensioningDcsColumns: GridColDef[] = [
    { field: Characteristics.DcProductName.id, width: 200 },
    { field: Characteristics.DcCurrent.id, width: 200 },
    { field: Characteristics.DcMargin.id, maxWidth: 150 },
    { field: Characteristics.DcModuleFrameSize.id, width: 200 },
    { field: Characteristics.DcModuleDimension.id, width: 200 },
    { field: Characteristics.DcThyristorTemp.id, width: 200 },
    { field: Characteristics.DcThyristorMaxTemp.id, width: 200 }
]
