import { Divider, IconButton, Menu, MenuItem } from "@mui/material"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import usePopover from "../../../../hooks/usePopover"
import { useAppDispatch, useAppSelector } from "../../../../store/store"
import { SalesSlice } from "../../../../features/sales/sales.slice"
import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../../../features/translations/TranslationKeys"
import CatalogUtils from "../../../../features/catalog/catalog.utils"
import { NumberValue } from "@encoway/c-services-js-client"
import { Characteristics } from "../../../../features/catalog/catalog.constants"
import useConfigurationProduct from "../../../../features/sales/hooks/useConfigurationProduct"

export function LayoutMenuButton() {
    const configurationTreeIsAvailable = useAppSelector(state => state.sales.configurationTreeIsAvailable)
    const visualizationIsAvailable = useAppSelector(state => state.sales.visualizationIsAvailable)
    const hideConfigurationTree = useAppSelector(state => state.sales.hideConfigurationTree)
    const hideConfiguration = useAppSelector(state => state.sales.hideConfiguration)
    const hideVisualization = useAppSelector(state => state.sales.hideVisualization)

    const product = useConfigurationProduct()

    const disableConfigurationTree = product
        ? CatalogUtils.getCharacteristicValue<NumberValue>(product, Characteristics.HideConfigurationTree.id)?.value === 1
        : false

    const popover = usePopover()
    const dispatch = useAppDispatch()

    const toggleConfigurationTree = () => {
        dispatch(SalesSlice.actions.changeConfigurationLayout({ hideConfigurationTree: !hideConfigurationTree }))
        popover.close()
    }

    const toggleConfiguration = () => {
        dispatch(SalesSlice.actions.changeConfigurationLayout({ hideConfiguration: !hideConfiguration }))
        popover.close()
    }

    const toggleVisualization = () => {
        dispatch(SalesSlice.actions.changeConfigurationLayout({ hideVisualization: !hideVisualization }))
        popover.close()
    }

    const focusConfiguration = () => {
        dispatch(SalesSlice.actions.changeConfigurationLayout({ hideConfigurationTree: false, hideVisualization: true, hideConfiguration: false }))
        popover.close()
    }

    const focusVisualization = () => {
        dispatch(SalesSlice.actions.changeConfigurationLayout({ hideConfigurationTree: true, hideVisualization: false, hideConfiguration: false }))
        popover.close()
    }

    const showOnlyConfiguration = () => {
        dispatch(SalesSlice.actions.changeConfigurationLayout({ hideConfigurationTree: true, hideVisualization: true, hideConfiguration: false }))
        popover.close()
    }

    const resetLayout = () => {
        dispatch(SalesSlice.actions.changeConfigurationLayout({ hideConfigurationTree: false, hideVisualization: false, hideConfiguration: false }))
        popover.close()
    }

    const onlyConfigurationIsShown = hideConfigurationTree && hideVisualization && !hideConfiguration
    const configurationIsFocused = !hideConfigurationTree && hideVisualization && !hideConfiguration
    const visualizationIsFocused = hideConfigurationTree && !hideVisualization && !hideConfiguration
    const layoutIsDefault = !hideConfigurationTree && !hideVisualization && !hideConfiguration

    return (
        <>
            <IconButton
                onClick={event => popover.open(event.currentTarget)}
                sx={popover.isOpen ? { backgroundColor: theme => theme.palette.divider } : undefined}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                anchorEl={popover.anchorEl}
                open={popover.isOpen}
                onClose={popover.close}
                disableScrollLock
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
            >
                {configurationTreeIsAvailable && (
                    <MenuItem onClick={toggleConfigurationTree} disabled={disableConfigurationTree}>
                        {hideConfigurationTree || disableConfigurationTree
                            ? L10n.format(TranslationKeys.pages.configuration.layoutOptions.showConfigurationTree)
                            : L10n.format(TranslationKeys.pages.configuration.layoutOptions.hideConfigurationTree)}
                    </MenuItem>
                )}
                {visualizationIsAvailable && (
                    <MenuItem onClick={toggleVisualization}>
                        {hideVisualization
                            ? L10n.format(TranslationKeys.pages.configuration.layoutOptions.showVisualization)
                            : L10n.format(TranslationKeys.pages.configuration.layoutOptions.hideVisualization)}
                    </MenuItem>
                )}
                <MenuItem onClick={toggleConfiguration}>
                    {hideConfiguration
                        ? L10n.format(TranslationKeys.pages.configuration.layoutOptions.showConfiguration)
                        : L10n.format(TranslationKeys.pages.configuration.layoutOptions.hideConfiguration)}
                </MenuItem>
                <Divider />
                <MenuItem onClick={showOnlyConfiguration} disabled={onlyConfigurationIsShown}>
                    {L10n.format(TranslationKeys.pages.configuration.layoutOptions.showOnlyConfiguration)}
                </MenuItem>
                <MenuItem onClick={focusConfiguration} disabled={configurationIsFocused}>
                    {L10n.format(TranslationKeys.pages.configuration.layoutOptions.focusConfiguration)}
                </MenuItem>
                <MenuItem onClick={focusVisualization} disabled={visualizationIsFocused}>
                    {L10n.format(TranslationKeys.pages.configuration.layoutOptions.focusVisualization)}
                </MenuItem>
                <Divider />
                <MenuItem onClick={resetLayout} disabled={layoutIsDefault}>
                    {L10n.format(TranslationKeys.pages.configuration.layoutOptions.resetLayout)}
                </MenuItem>
            </Menu>
        </>
    )
}
