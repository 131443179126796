import { GridOverlay } from "@mui/x-data-grid-pro"
import AbbSpinner from "../../../../pages/configurationPage/components/cui/components/AbbSpinner/AbbSpinner"

export function LoadingOverlay() {
    return (
        <GridOverlay>
            <AbbSpinner />
        </GridOverlay>
    )
}
