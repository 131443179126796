import { SxProps } from "@mui/system"

export const ErrorAlertStyles: SxProps = {
    ".MuiAlert-icon": {
        alignItems: "center"
    },
    ".MuiAlert-message": {
        padding: 0
    }
}
